import React, { useEffect, useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import styles from './index.module.scss';
import AccountMonitoringPage from '../account-monitoring';
import clsx from 'clsx';
import MonitoringDevices from '../monitoring-devices';
import FilterSidebarComponent from '../../components/admin/fliter-sidebar';
import monitoringService from '../../services/monitoringService';
import moment from 'moment';
import { MonitoringUsersType, MonitoringDevicesType } from '../../types/monitoringTypes';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import authService from '../../services/authService';

const page = 0;
const limit = 999;

export type FilterParams = {
  page?: number;
  limit?: number;
  countryId?: string;
  cityId?: string;
  hospitalId?: string[];
  userRoleId?: number[];
  batteryLevel?: boolean;
  needToTrackTelemetry?: boolean;
  firebaseDeviceToken?: boolean;
  lastTelemetryDiff?: boolean;
  monitoring?: string;
  problemDevices?: boolean;
  problemAccounts?: boolean;
};

export type SortParams = {
  sortField?: string;
  sortDirection?: string;
};

export type LocationSample = {
  id: string,
  name: string
};

const MonitoringPage = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'main.monitoring' });
  const navigate = useNavigate();
  const [problemDevicesAccountsList, setProblemDevicesAccountsList] = useState<MonitoringDevicesType[]>([]);
  const [problemUsersAccountsList, setProblemUsersAccountsList] = useState<MonitoringUsersType[]>([]);
  const [devicesAccountProblemsCount, setDevicesAccountProblemsCount] = useState(problemDevicesAccountsList.length);
  const [usersAccountProblemsCount, setUsersAccountProblemsCount] = useState(problemUsersAccountsList.length);
  const [currentDataDevices, setCurrentDataDevices] = useState<MonitoringDevicesType[]>([]);
  const [currentDataUsers, setCurrentDataUsers] = useState<MonitoringUsersType[]>([]);
  const [isDevicesMonitoring, setIsDevicesMonitoring] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedFilterParams, setSelectedFilterParams] = useState<FilterParams>({
    page: page,
    limit: limit,
    countryId: undefined,
    cityId: undefined,
    hospitalId: undefined,
    userRoleId: undefined,
    batteryLevel: undefined,
    needToTrackTelemetry: false,
    firebaseDeviceToken: undefined,
    lastTelemetryDiff: undefined,
    monitoring: undefined,
    problemDevices: undefined,
    problemAccounts: undefined
  });
  const [selectedSortParams, setSelectedSortParams] = useState<SortParams>({
    sortDirection: undefined,
    sortField: undefined
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isOpenMenu, setIsOpenMenu] = useState(true);

  useEffect(() => {
    (async () => {
      const { isAdminUser, roles } = await authService.getMyProfile();
      if (!isAdminUser) navigate(`/hospital/${roles[0]?.department?.hospital?.id}/main`);
      setIsLoading(false);
    })();
    
    return setIsLoading(true);
  }, []);

  const searchParamsToFilterParams = (params: URLSearchParams) => {
    return {
      page: Number(params.get('page')) || page,
      limit: Number(params.get('limit')) || limit,
      ...(params.get('countryId') && {countryId: params.get('countryId')?.toString()}),
      ...(params.get('cityId') && {cityId: params.get('cityId')?.toString()}),
      ...(params.getAll('hospitalId') && {hospitalId: params.getAll('hospitalId')}),
      ...(params.getAll('userRoleId') && {userRoleId: params.getAll('userRoleId').map(Number)}),
      ...(params.get('batteryLevel') && {batteryLevel: params.get('batteryLevel') === 'true'}),
      ...(params.get('needToTrackTelemetry') && {needToTrackTelemetry: params.get('needToTrackTelemetry') === 'true'}),
      ...(params.get('firebaseDeviceToken') && {firebaseDeviceToken: params.get('firebaseDeviceToken') === 'true'}),
      ...(params.get('lastTelemetryDiff') && {lastTelemetryDiff: params.get('lastTelemetryDiff') === 'true'}),
      ...(params.get('sortField') && {sortField: params.get('sortField')?.toString()}),
      ...(params.get('sortDirection') && {sortDirection: params.get('sortDirection')?.toString()}),
      ...(params.get('monitoring') && {monitoring: params.get('monitoring')?.toString()}),
      ...(params.get('problemAccounts') && {problemAccounts: params.get('problemAccounts') === 'true'}),
      ...(params.get('problemDevices') && {problemDevices: params.get('problemDevices') === 'true'}),
    };
  };

  const fetchUsersData = async (params: FilterParams) => {
    setCurrentDataUsers(await monitoringService.getMonitoringUsersData(params));
  };

  const fetchDevicesData = async (params: FilterParams) => {
    setCurrentDataDevices(await monitoringService.getMonitoringDevicesData(params));
  };

  const applyFilters = async () => {
    if (isDevicesMonitoring) await fetchDevicesData(selectedFilterParams);
    if (!isDevicesMonitoring) await fetchUsersData(selectedFilterParams);
  };

  useEffect(() => {
    (async () => {
      const initialParams = searchParamsToFilterParams(searchParams);
      setSelectedFilterParams(initialParams);
      setSelectedSortParams(initialParams);

      if (initialParams.monitoring === 'devices') {
        setIsDevicesMonitoring(true);
        await fetchDevicesData(initialParams);
      } else if (initialParams.monitoring === 'accounts') {
        setIsDevicesMonitoring(false);
        await fetchUsersData(initialParams);
      }
    })();
  }, []);

  useEffect(() => {
    const problemUsersAccountList = currentDataUsers.filter((item: any) =>
      isProblemUsersAccount(item?.batteryLevel, item?.lastEvent, item?.user?.firebaseDeviceToken)
    )
    setProblemUsersAccountsList(problemUsersAccountList);
    setUsersAccountProblemsCount(problemUsersAccountList?.length);
  }, [currentDataUsers]);

  useEffect(() => {
    const problemDevicesAccountList = currentDataDevices.filter((item: any) =>
      isProblemDevicesAccount(item?.batteryLevel, item?.lastEvent)
    );
    setProblemDevicesAccountsList(problemDevicesAccountList);
    setDevicesAccountProblemsCount(problemDevicesAccountList?.length);
  }, [currentDataDevices]);

  useEffect(() => {
    (async () => {
      let newSearchParams = new URLSearchParams();

      Object.entries(selectedFilterParams).forEach(([key, value]) => {
        if (Array.isArray(value) && value.length !== 0) {
          value.forEach(val => {
            newSearchParams.append(key, val.toString());
          });
        } else if ((value || value === false) && key !== 'hospitalId' && key !== 'userRoleId') {
          newSearchParams.append(key, value.toString());
        }
      });
      setSearchParams(newSearchParams);

      setTimeout(async () => {
        await applyFilters();
      }, 500);
    })();
  }, [selectedFilterParams]);

  useEffect(() => {
    (async () => {
      let newSearchParams = searchParams;
      newSearchParams.delete('sortField');
      newSearchParams.delete('sortDirection');

      if (selectedSortParams.sortField && selectedSortParams.sortDirection) {
        newSearchParams.append('sortField', selectedSortParams?.sortField);
        newSearchParams.append('sortDirection', selectedSortParams?.sortDirection);
      }

      setSearchParams(newSearchParams);
  })();
  }, [selectedSortParams]);

  const changeMonitoring = async () => {
    setIsDevicesMonitoring(!isDevicesMonitoring);
    const newPage = isDevicesMonitoring ? 'accounts' : 'devices';

    setSelectedFilterParams({
      hospitalId: [],
      userRoleId: [],
      page: selectedFilterParams?.page,
      limit: selectedFilterParams?.limit,
      monitoring: newPage,
      problemAccounts: undefined,
      problemDevices: undefined
    });
  }

  const toggleSort = async (field: string) => {
    if (selectedSortParams?.sortField === field) {
      const newDirection = (selectedSortParams?.sortDirection === 'asc') ? 'desc' : 'asc';
      setSelectedSortParams({sortDirection: newDirection, sortField: field});
    } else {
      setSelectedSortParams({sortDirection: 'asc', sortField: field});
    }
  };

  const isProblemDevicesAccount = (battery: number, date: string) => {
    if (battery <= 20 || (moment().diff(moment(new Date(date)), 'hours') >= 2)) return true
  };

  const isProblemUsersAccount = (battery: number, date: string, token: boolean) => {
    if (battery <= 20 || (moment().diff(moment(new Date(date)), 'hours') >= 2) || !token) return true
  };

  const handleButtonClickProblemDevicesAccount = async () => {
    if (!selectedFilterParams?.problemDevices) {
      setSelectedFilterParams({
        ...selectedFilterParams,
        problemDevices: true,
        lastTelemetryDiff: true,
        batteryLevel: true
      })
    } else {
      setSelectedFilterParams({
        ...selectedFilterParams,
        problemDevices: undefined,
        lastTelemetryDiff: undefined,
        batteryLevel: undefined
      })
    }
  };

  const handleButtonClickProblemUsersAccount = async () => {
    if (!selectedFilterParams?.problemAccounts) {
      setSelectedFilterParams({
        ...selectedFilterParams,
        problemAccounts: true,
        firebaseDeviceToken: false,
        lastTelemetryDiff: true,
        batteryLevel: true
      })
    } else {
      setSelectedFilterParams({
        ...selectedFilterParams,
        problemAccounts: undefined,
        firebaseDeviceToken: undefined,
        lastTelemetryDiff: undefined,
        batteryLevel: undefined
      })
    }
  };

  const theme = createTheme({
    typography: {
      allVariants: {
        fontFamily: "Manrope, 'serif'",
        textTransform: 'none',
        fontSize: 16,
      },
    },
  });

  return (!isLoading ?
    <ThemeProvider theme={theme}>
      <div className={styles.wrapperContainer}>
        <div className={styles.container}>
          <div className={styles.header}>
            <div className={styles.items}>
              <Link to="/nav-bar" className={clsx(styles.logo, !isOpenMenu ? styles.logoTransition : null)}>
                <img
                  src="/images/logo.png"
                  alt="background"
                />
              </Link>
              <Link to="/nav-bar" className={styles.pace}>
              <img
                src="/images/pace.svg"
                alt="pace"
              />
              </Link>
              <h1 className={styles.title}>{t('title')}</h1>
              <div className={styles.buttonContainer}>
                <button
                  className={clsx(
                    styles.monitoringButton,
                    isDevicesMonitoring ? styles.selectedButton : null
                  )}
                  onClick={changeMonitoring}
                >
                  {t('devices')}
                </button>
                <button
                  className={clsx(
                    styles.monitoringButton,
                    !isDevicesMonitoring ? styles.selectedButton : null)
                  }
                  onClick={changeMonitoring}
                >
                  {t('accounts')}
                </button>
              </div>
            </div>
            {isDevicesMonitoring ?
              <button
                className={clsx(
                  styles.button,
                  devicesAccountProblemsCount > 0 ? styles.errorButton : null,
                  devicesAccountProblemsCount > 0 && selectedFilterParams?.problemDevices ? styles.errorButtonPressed : null
                )}
                onClick={handleButtonClickProblemDevicesAccount}
              >
                <div
                  className={clsx(
                    styles.body,
                    devicesAccountProblemsCount > 0 && styles.errorBody,
                    devicesAccountProblemsCount > 0 && selectedFilterParams?.problemDevices ? styles.errorBodyPressed : null
                  )}
                >
                  {t('problem-devices')}
                </div>
                {!selectedFilterParams?.problemDevices && devicesAccountProblemsCount > 0 ? (
                  <div
                    className={clsx(
                      styles.number,
                      selectedFilterParams?.problemDevices ? styles.numberError : null
                    )}
                  >
                    {devicesAccountProblemsCount}
                  </div>
                ) : null}
              </button> :
              <button
                className={clsx(
                  styles.button,
                  usersAccountProblemsCount > 0 ? styles.errorButton : null,
                  usersAccountProblemsCount > 0 && selectedFilterParams?.problemAccounts ? styles.errorButtonPressed : null
                )}
                onClick={handleButtonClickProblemUsersAccount}
              >
                <div
                  className={clsx(
                    styles.body,
                    usersAccountProblemsCount > 0 && styles.errorBody,
                    usersAccountProblemsCount > 0 && selectedFilterParams?.problemAccounts ? styles.errorBodyPressed : null
                  )}
                >
                  {t('problem-accounts')}
                </div>
                {!selectedFilterParams?.problemAccounts && usersAccountProblemsCount > 0 ? (
                  <div
                    className={clsx(
                      styles.number,
                      selectedFilterParams?.problemAccounts ? styles.numberError : null
                    )}
                  >
                    {usersAccountProblemsCount}
                  </div>
                ) : null}
              </button>
            }
          </div>
          <div className={styles.tableContainer}>
            <FilterSidebarComponent
              isDevicesMonitoring={isDevicesMonitoring}
              selectedFilterParams={selectedFilterParams}
              setSelectedFilterParams={setSelectedFilterParams}
              fetchData={isDevicesMonitoring ? fetchDevicesData : fetchUsersData}
              isOpenMenu={isOpenMenu}
              setIsOpenMenu={setIsOpenMenu}
            />
            {isDevicesMonitoring ?
              <MonitoringDevices
                currentDataDevices={selectedFilterParams?.problemDevices ? problemDevicesAccountsList : currentDataDevices}
                setCurrentDataDevices={selectedFilterParams?.problemDevices ? setProblemDevicesAccountsList : setCurrentDataDevices}
                isProblemDevicesAccount={isProblemDevicesAccount}
                sortField={selectedSortParams?.sortField}
                sortDirection={selectedSortParams?.sortDirection}
                toggleSort={toggleSort}
              /> :
              <AccountMonitoringPage
                currentDataUsers={selectedFilterParams?.problemAccounts ? problemUsersAccountsList : currentDataUsers}
                setCurrentDataUsers={selectedFilterParams?.problemAccounts ? setProblemUsersAccountsList : setCurrentDataUsers}
                isProblemUsersAccount={isProblemUsersAccount}
                sortField={selectedSortParams?.sortField}
                sortDirection={selectedSortParams?.sortDirection}
                toggleSort={toggleSort}
              />
            }
          </div>
        </div>
      </div>
    </ThemeProvider> : null
  );
};

export default MonitoringPage;