import React, { useEffect, useState } from 'react';
import Bar from '../../components/bar';
import styles from './index.module.scss'
import { useNavigate } from "react-router-dom";
import Logo from '../../components/logo';
import { useTranslation } from 'react-i18next';
import authService from '../../services/authService';

const NavBarPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('translation', { keyPrefix: 'nav-bar' });
  const [isLoading, setIsLoading] = useState(true);
  
  useEffect(() => {
    (async () => {
      const { isAdminUser, roles } = await authService.getMyProfile();
      if (!isAdminUser) navigate(`/hospital/${roles[0]?.department?.hospital?.id}/main`);
      setIsLoading(false);
    })();
    
    return setIsLoading(true);
  }, []);

  const handleLogOut = async () => {
    try {
      await authService.logOut();
      navigate('/sign-in');
    } catch (e){
      console.log(e);
    }
  };

  return (!isLoading ?
    <div className={styles.container}>
      <div className={styles.navBarContainer}>
        <Logo/>
        <div className={styles.navBar}>
          <Bar to={'/hospital-list'}>{t('statistics')}</Bar>
          <Bar to={'/monitoring'}>{t('monitoring')}</Bar>
        </div>
        <button
          className={styles.buttonTitle}
          onClick={handleLogOut}
        >
          {t('logout')}
        </button>
      </div>
    </div> : null
  );
};

export default NavBarPage;