import React, {useEffect, useState} from 'react';
import styles from '../monitoring-devices/index.module.scss'
import {
  Table, TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import clsx from 'clsx';
import moment from 'moment';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import timeUtils from '../../utils/timeUtils';
import {MonitoringUsersType} from '../../types/monitoringTypes';
import CopyButton from '../../components/copyButton';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import {useTranslation} from 'react-i18next';
import removeAfterHyphenUtils from '../../utils/removeAfterHyphenUtils';

type MonitoringUsersProps = {
  currentDataUsers: MonitoringUsersType[];
  setCurrentDataUsers(value: MonitoringUsersType[]): void;
  isProblemUsersAccount: any;
  sortField?: string;
  sortDirection?: string;
  toggleSort(value: string): void;
}

const columns = [
  {id: 'personnelAndRole', label: 'Користувач Роль'},
  {id: 'phoneAndMail', label: 'Телефон Пошта'},
  {id: 'hospitalAndDepartment', label: 'Лікарня Відділення'},
  {id: 'lastTelemetryTs', label: 'Телеметрія'},
  {id: 'version', label: 'Версія'},
  {id: 'batteryLevel', label: 'Рівень заряду'},
  {id: 'token', label: 'Токен'},
];

const AccountMonitoringPage = (props: MonitoringUsersProps) => {
  const {
    currentDataUsers,
    setCurrentDataUsers,
    isProblemUsersAccount,
    sortField,
    sortDirection,
    toggleSort
  } = props

  const { t } = useTranslation('translation', { keyPrefix: 'main.monitoring' });
  const [isAscendingPersonnelRole, setIsAscendingPersonnelRole] = useState(false);
  const [isAscendingBattery, setIsAscendingBattery] = useState(false);
  const [isAscendingHospitalName, setIsAscendingHospitalName] = useState(false);
  const [isAscendingTelemetry, setIsAscendingTelemetry] = useState(false);
  const [isAscendingToken, setIsAscendingToken] = useState(false);

  useEffect(() => {
    if (sortField) {
      sortData(sortField);
    }
  }, [sortField, sortDirection]);

  const sortData = (field: string) => {
    if (field === 'sortByPersonnelRole') sortByPersonnelRole();
    if (field === 'sortByHospitalName') sortByHospitalName();
    if (field === 'sortByTelemetryTime') sortByTelemetryTime();
    if (field === 'sortByBatteryLevel') sortByBatteryLevel();
    if (field === 'sortByActivityToken') sortByActivityToken();
  };

  const sortByPersonnelRole = () => {
    setIsAscendingPersonnelRole(!isAscendingPersonnelRole);
    const sortPersonnelRole = currentDataUsers.sort((a, b) => {
      if (a?.user?.roles?.map((item) => {
        return item?.roleName
      }) < b?.user?.roles?.map((item) => {
        return item?.roleName
      }))
        return sortDirection === "desc" ? 1 : -1;

      if (a?.user?.roles?.map((item) => {
        return item?.roleName
      }) > b?.user?.roles?.map((item) => {
        return item?.roleName
      }))
        return sortDirection === "asc" ? 1 : -1;

      return 0;
    });
    setCurrentDataUsers(sortPersonnelRole);
  }

  const sortByHospitalName = () => {
    setIsAscendingHospitalName(!isAscendingHospitalName);
    const sortHospitalName = currentDataUsers.sort((a, b) => {
      if (a?.user?.roles?.map((item) => {
        return item?.department?.hospital?.name
      }) < b?.user?.roles?.map((item) => {
        return item?.department?.hospital?.name
      }))
        return sortDirection === "desc" ? 1 : -1;

      if (a.user.roles.map((item) => {
        return item.department.hospital.name
      }) > b?.user?.roles?.map((item) => {
        return item?.department?.hospital?.name
      }))
        return sortDirection === "asc" ? 1 : -1;

      return 0;
    });
    setCurrentDataUsers(sortHospitalName);
  }

  const sortByTelemetryTime = () => {
    setIsAscendingTelemetry(!isAscendingTelemetry);
    const sortTelemetryTime = currentDataUsers.sort((a, b) => {
      const timeA = moment(a?.lastEvent);
      const timeB = moment(b?.lastEvent);
      if (sortDirection === "desc")
        return timeA.valueOf() - timeB.valueOf();

      return timeB.valueOf() - timeA.valueOf();
    });
    setCurrentDataUsers(sortTelemetryTime);
  }

  const sortByBatteryLevel = () => {
    setIsAscendingBattery(!isAscendingBattery);
    const sortBatteryLevel = currentDataUsers.sort((a, b) => {
      if (sortDirection === "asc")
        return a?.batteryLevel - b?.batteryLevel;

      return b?.batteryLevel - a?.batteryLevel;
    });
    setCurrentDataUsers(sortBatteryLevel);
  }

  const sortByActivityToken = () => {
    setIsAscendingToken(!isAscendingToken);
    const sortToken = currentDataUsers.sort((a, b) => {
      if (sortDirection === "desc")
        return a?.user?.firebaseDeviceToken === b?.user?.firebaseDeviceToken ? 0 : a?.user?.firebaseDeviceToken ? -1 : 1

      return a?.user?.firebaseDeviceToken === b?.user?.firebaseDeviceToken ? 0 : a?.user?.firebaseDeviceToken ? 1 : -1
    });
    setCurrentDataUsers(sortToken);
  }

  const handleCellViewLabel = (columnType: string) => {
    switch (columnType) {
      case 'personnelAndRole':
        return <div className={styles.headLabel}>
          <div>
            <p className={styles.firstLabel}>{t('table-header.user')}</p>
            <p className={styles.secondLabel}>{t('table-header.role')}</p>
          </div>
          <button onClick={() => toggleSort("sortByPersonnelRole")}>
            {((sortDirection === 'asc' || sortDirection === undefined) && sortField === "sortByPersonnelRole") ?
              <ArrowDropUpIcon/> :
              <ArrowDropDownIcon/>
            }
          </button>
        </div>
      case 'phoneAndMail':
        return <div className={styles.headLabel}>
          <div>
            <p className={styles.firstLabel}>{t('table-header.phone')}</p>
            <p className={styles.secondLabel}>{t('table-header.mail')}</p>
          </div>
        </div>
      case 'hospitalAndDepartment':
        return <div className={styles.headLabel}>
          <div>
            <p className={styles.firstLabel}>{t('table-header.hospital')}</p>
            <p className={styles.secondLabel}>{t('table-header.department')}</p>
          </div>
          <button onClick={() => toggleSort("sortByHospitalName")}>
            {((sortDirection === 'asc' || sortDirection === undefined) && sortField === "sortByHospitalName") ?
              <ArrowDropUpIcon/> :
              <ArrowDropDownIcon/>
            }
          </button>
        </div>
      case 'lastTelemetryTs':
        return <div className={styles.headLabel}>
          <p className={styles.firstLabel}>{t('table-header.telemetry')}</p>
          <button onClick={() => toggleSort("sortByTelemetryTime")}>
            {((sortDirection === 'asc' || sortDirection === undefined) && sortField === "sortByTelemetryTime") ?
              <ArrowDropUpIcon/> :
              <ArrowDropDownIcon/>
            }
          </button>
        </div>
      case 'version':
        return <div className={styles.headLabel}>
          <p className={styles.firstLabel}>{t('table-header.version')}</p>
        </div>
      case 'batteryLevel':
        return <div className={styles.headLabel}>
          <p className={styles.firstLabel}>{t('table-header.battery-level')}</p>
          <button onClick={() => toggleSort("sortByBatteryLevel")}>
            {((sortDirection === 'asc' || sortDirection === undefined) && sortField === "sortByBatteryLevel") ?
              <ArrowDropUpIcon/> :
              <ArrowDropDownIcon/>}
          </button>
        </div>
      case 'token':
        return <div className={styles.headLabel}>
          <p className={styles.firstLabel}>{t('table-header.token')}</p>
          <button onClick={() => toggleSort("sortByActivityToken")}>
            {((sortDirection === 'asc' || sortDirection === undefined) && sortField === "sortByActivityToken") ?
              <ArrowDropUpIcon/> :
              <ArrowDropDownIcon/>
            }
          </button>
        </div>
      default:
        return null
    }
  }

  const handleCellView = (value: any, columnType: string, hospital: MonitoringUsersType) => {
    switch (columnType) {
      case 'personnelAndRole':
        return <div>
          <p>{hospital?.user?.firstName} {hospital?.user?.lastName}</p>
          <p className={styles.secondParagraph}>{hospital?.user?.roles?.map((item) => {
            return t(`roles.${item?.roleName?.toLowerCase()}`)
          })}</p>
        </div>
      case 'phoneAndMail':
        return <div>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
            <p>{hospital?.user?.phone}</p>
            <CopyButton copy={hospital?.user?.phone}/>
          </div>
          <p className={styles.secondParagraph}>{hospital?.user?.email}</p>
        </div>
      case 'hospitalAndDepartment':
        return <div>
          <p>{hospital?.user?.roles?.map((item) => {
            return item?.department?.hospital?.name
          })}</p>
          <p className={styles.secondParagraph}>{hospital?.user?.roles?.map((item) => {
            return item?.department?.name
          })}</p>
        </div>
      case 'lastTelemetryTs':
        return <div>
          <p className={clsx(moment().diff(moment(new Date(hospital?.lastEvent)), 'hours') >= 2 && styles.error)}>
            {hospital?.lastEvent === null ? "" : timeUtils.getTimeDifference(hospital?.lastEvent)}
          </p>
          <p className={styles.secondParagraph}>
            {hospital?.lastEvent === null ? "" : moment(new Date(hospital?.lastEvent))?.format('HH:mm DD.MM.YY ')}
          </p>
        </div>
      case 'version':
        return <p>{removeAfterHyphenUtils.removeAfterHyphen(hospital?.androidAppVersion)}</p>
      case 'batteryLevel':
        return <div className={styles.batteryLevelContainer}>
          {hospital?.isCharging && hospital?.batteryLevel <= 20 ?
            <img
              src="/images/charging.svg"
              alt="charging"
              style={{marginRight: '10px'}}
            /> :
            null
          }
          <p className={clsx(value <= 20 && styles.error)}
             style={{marginRight: '50%'}}
          >
            {hospital?.batteryLevel === null ? "" : `${hospital?.batteryLevel}%`}
          </p>
        </div>
      case 'token':
        return <div style={{maxWidth: '100px'}}
                    className={styles.secondParagraph}>
          {hospital?.user?.firebaseDeviceToken ?
          "Active" :
          <p className={styles.error}>Inactive</p>
          }
        </div>
      default:
        return value
    }
  };

  return (
    <div className={styles.tableContainer}>
      <TableContainer
        className={styles.tableWrapper}
        sx={{maxHeight: '70vh'}}>
        <Table
          stickyHeader
          aria-labelledby="sticky table"
          size="small"
          className={styles.table}
          style={{borderCollapse: "collapse"}}>
          <TableHead>
            <TableRow>
              {columns.map((item: any) => (
                <TableCell
                  key={item.id}
                  align="left"
                  className={styles.tableHeadCell}
                >
                  {handleCellViewLabel(item?.id)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {currentDataUsers.map((row: any, index: number) => {
              return (
                <TableRow
                  tabIndex={-1}
                  key={index.toString()}
                  className={clsx(styles.row,
                    isProblemUsersAccount(row?.batteryLevel, row?.lastEvent, row?.user?.firebaseDeviceToken) ?
                      styles.errorRow :
                      null
                  )}
                >
                  {columns.map((column, index) => {
                    return (
                      <TableCell
                        key={index.toString()}
                        align="left"
                        className={styles.firstParagraph}
                      >
                        {handleCellView(row[column?.id], column?.id, row)}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default AccountMonitoringPage;