import moment from "moment";

const convertSecondsToHMS = (seconds: number) => {
  const locale = sessionStorage.getItem('locale');

  if (!locale) return '0';

  if (isNaN(seconds) || seconds < 0) {
    return "Некоректне значення";
  }

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  let result = "";

  if (hours > 0) {
    result += hours + (locale === 'en' ? ' h ' : ' год ');
  }

  if (minutes > 0) {
    result += minutes + (locale === 'en' ? ' min ' : ' хв ');
  }

  if (remainingSeconds > 0) {
    result += Math.round(remainingSeconds) + (locale === 'en' ? ' s ' : ' с');
  }

  return result.trim().toString();
};

const getTimeDifference = (time:string) => {
  const locale = sessionStorage.getItem('locale');

  const currentTime = moment();
  const diff = moment.duration(currentTime.diff(moment(new Date(time))));
  const years = diff.years()
  const months = diff.months();
  const days = diff.days();
  const hours = diff.hours();
  const minutes = diff.minutes();
  const seconds = diff.seconds();

  let differenceString = '';
  if (years > 0) {
    differenceString += years + (locale === 'en' ? ' y '  : locale === 'pl' ? ' r ' : 'p');
  }
  if (months > 0) {
    differenceString += months + (locale === 'en' ? ' m ' : locale === 'pl' ? ' m ' : ' м ');
  }
  if (days > 0) {
    differenceString += days + (locale === 'en' ? ' d ' : locale === 'pl' ? ' d ' : ' д ');
  }
  if (hours > 0) {
    differenceString += hours + (locale === 'en' ? ' h ' : locale === 'pl' ? ' godz ' : ' год ');
  }
  if (minutes > 0) {
    differenceString += minutes + (locale === 'en' ? ' min ' : locale === 'pl' ? ' min ' : ' хв ');
  }
  if (seconds > 0) {
    differenceString += seconds + (locale === 'en' ? ' sec ' : locale === 'pl' ? ' sek ' : ' c ');
  }

  return differenceString;
}

export default {
  convertSecondsToHMS,
  getTimeDifference
}