import React, { useState } from 'react';
import styles from './index.module.scss';
import ReceptionDepartmentComponent from './components/reception-department';
import BlueCodeDepartmentComponent from './components/blue-code-department';
import BetweenDepartmentComponent from './components/between-department';
import NursesDepartmentComponent from './components/nurses-department';
import ProcessComponent from './components/process';
import Button from '../../common/button';
import CalendarButtonComponent from '../../common/calendar-button';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';

const MainComponent = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation('translation');
  const [isCongratulationsTooltip, setIsCongratulationsTooltip] = useState(false);

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <div>
          <p className={styles.title}>{t('main.title')}</p>
          {isCongratulationsTooltip && (
            <div className={styles.congratulationsContainer}>
              <p>{t('main.congratulation')}</p>
              <p>{t('main.congratulationText')}</p>
            </div>
          )}
        </div>
        <div className={styles.buttonContainer}>
          <Button
            color="red"
            onClick={() => navigate(`/hospital/${id}/problem-calls`)}
          >
            {t('problem-calls.title')}
          </Button>
          <CalendarButtonComponent />
        </div>
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.subContainer}>
          <ReceptionDepartmentComponent />
          <BlueCodeDepartmentComponent />
        </div>
        <div className={styles.subContainer}>
          <BetweenDepartmentComponent />
          <NursesDepartmentComponent />
          <ProcessComponent />
        </div>
      </div>
    </div>
  )
};

export default MainComponent;