import React, {useEffect, useState} from 'react';
import styles from "./index.module.scss"
import st from '../fliter-sidebar/index.module.scss'
import { FormControl, FormControlLabel, Checkbox } from "@mui/material";
import { ArrowDropUp, ArrowDropDown } from "@mui/icons-material";
import { FilterParams, LocationSample } from '../../../pages/monitoring';

type AdminCheckboxProps = {
  data: string[] | LocationSample[];
  title: string;
  children: any;
  selectedFilterParams: FilterParams;
  setSelectedFilterParams: (params: FilterParams) => void;
  currentCheckbox: string;
};

const AdminCheckbox = (props: AdminCheckboxProps) => {
  const {
    data,
    title,
    children,
    selectedFilterParams,
    setSelectedFilterParams,
    currentCheckbox
  } = props;

  const [expanded, setExpanded] = useState<boolean>(true);
  const [tokenChecked, setTokenChecked] = useState<boolean[]>([false, false]);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const handleRoleCheck = (checked: boolean, index: number) => {
    let newSelectedUsers = selectedFilterParams.userRoleId;

    if (checked) {
      if (newSelectedUsers !== undefined && !newSelectedUsers?.find(
        (id: string | number) => id === index + 1)) {
        newSelectedUsers.push(index + 1);
      }
    } else {
      newSelectedUsers = newSelectedUsers?.filter((id: string | number) => id !== index + 1);
    }
    setSelectedFilterParams({
      ...selectedFilterParams,
      userRoleId: newSelectedUsers,
    });
  };

  const handleTokenCheck = (checked: boolean, index: number) => {
    let newTokenChecked = tokenChecked;
    newTokenChecked[index] = checked;

    if (newTokenChecked[0] === newTokenChecked[1]) {
      setSelectedFilterParams({...selectedFilterParams, firebaseDeviceToken: undefined});
    } else {
      setSelectedFilterParams({
        ...selectedFilterParams,
        firebaseDeviceToken: newTokenChecked[index] !== Boolean(index)
      });
    }
  };

  useEffect(() => {
    if (selectedFilterParams?.problemAccounts) {
      setTokenChecked([false, true])
    } else {
      setTokenChecked([false, false]);
    }
  }, [selectedFilterParams?.problemAccounts]);

  const handleTelemetryTimeDiffCheck = (checked: boolean) => {
    setSelectedFilterParams({...selectedFilterParams, lastTelemetryDiff: checked});
  };

  const handleLowChargeLevelCheck = (checked: boolean) => {
    setSelectedFilterParams({...selectedFilterParams, batteryLevel: checked});
  };

  const handleCheckFilters = (checked: boolean, index: number) => {
    switch (currentCheckbox) {
      case 'roles':
        handleRoleCheck(checked, index);
        break;
      case 'firebaseDeviceToken':
        handleTokenCheck(checked, index);
        break;
      case 'batteryLevel':
        handleLowChargeLevelCheck(checked);
        break;
      case 'lastTelemetryDiff':
        handleTelemetryTimeDiffCheck(checked);
        break;
    }
  };

  const isCheckboxSelected = (item: string | LocationSample, index: number): boolean => {
    let isSelected = false;

    switch (currentCheckbox) {
      case 'roles':
        isSelected = !!selectedFilterParams?.userRoleId?.find((role) => {
          return role === Number(typeof item !== "string" ? item?.id : undefined)
        });
        break;
      case 'firebaseDeviceToken':
        isSelected = tokenChecked[index];
        break;
      case 'batteryLevel':
        isSelected = !!selectedFilterParams?.batteryLevel;
        break;
      case 'lastTelemetryDiff':
        isSelected = !!selectedFilterParams?.lastTelemetryDiff;
        break;
    }

    return isSelected;
  };

  return (
    <FormControl>
      <legend className={styles.titleContainer} onClick={toggleExpand}>
        <span className={st.labelContainer}>
          {children}
          <p className={styles.filterTitle}>{title}</p>
        </span>
        <span>{expanded ? <ArrowDropUp/> : <ArrowDropDown/>}</span>
      </legend>
      {expanded && data?.map((item, index) => (
        <FormControlLabel
          key={index}
          control={
            <Checkbox
              sx={{
                "& .MuiSvgIcon-root": {
                  fill: "#ffffff00",
                  border: 2,
                  borderColor: isCheckboxSelected(item, index) ? "#ffffff00" : "#BAC4E6",
                  borderRadius: 1.5,
                  background: isCheckboxSelected(item, index) ? "#526BC1B2" : "#ffffff00",
                },
              }}
              checked={isCheckboxSelected(item, index)}
              onChange={(event) => handleCheckFilters(event.target.checked, index)}
            />
          }
          label={typeof item === 'string' ? item : item.name}
        />
      ))}
    </FormControl>
  );
};

export default AdminCheckbox;