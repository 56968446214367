import axios, { AxiosRequestConfig } from "axios";
import { Auth } from "aws-amplify";

const get = (url: string) => requestApi('get', url);
const post = (url: string, requestConfig: any = {}) => requestApi('post', url, requestConfig);
const put = (url: string, requestConfig: any = {}) => requestApi('put', url, requestConfig);
const deleteApi = (url: string, requestConfig: any = {}) => requestApi('delete', url, requestConfig);

const requestApi = async (method: string, url: string, requestConfig: any = {}) => {
  try {
    let config: any = { method: method, url: requestConfig?.data?.otherUrl
        ? requestConfig?.data?.otherUrl : `${process.env.REACT_APP_BACKEND_API_URL}/${url}`
    };
    const awsAmplifyConfig = {
      region: process.env.REACT_APP_AWS_COGNITO_REGION,
      userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_WEB_CLIENT_ID,
      authenticationFlowType: 'USER_PASSWORD_AUTH'
    };
    Auth.configure(awsAmplifyConfig);

    const { idToken }: any = await Auth.currentSession();

    const updatedConfig = requestConfig;

    delete updatedConfig?.data?.otherUrl

    if(idToken) {
      config = {...config, headers: {'Authorization': 'Bearer ' + idToken?.jwtToken}};
    }

    config = {...config, ...updatedConfig};

    return await request(config);
  } catch (e: any) {
    console.log(`requestApi: url: ${url}; requestConfig: ${JSON.stringify(requestConfig)},
        method: ${method}; error: ${e}; response data: ${JSON.stringify(e.response?.data)}`);

    throw e;
  }
};

const request = (requestConfig: AxiosRequestConfig) => axios.request(requestConfig);

axios.interceptors.response.use(
  response => response,
  async error => {
    console.log(error);
    //window.location.replace(`${window.location.origin}/sign-in`);
  }
);

export default {
  get,
  put,
  post,
  deleteApi,
  requestApi
}