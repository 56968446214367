import React, { useMemo } from 'react';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import DepartmentCallTypeComponent from '../../../main/components/department-call-type';
import {CallType, ICallItem} from '../../../../../services/hospitalService';
import { ReactComponent as CardiomonitorIcon } from '../../../../../assets/cardiomonitor-icon.svg';
import { ReactComponent as NursesServiceCallIcon } from '../../../../../assets/nurses-service-call.svg';
import timeUtils from '../../../../../utils/timeUtils';
// import CallItemComponent from '../../../../common/call-item';

export type StatisticsActiveTabType = 'reception-department'
  | 'between-departments'
  | 'nurses-department'
  | 'blue-code-department';

type CallsStatisticComponentProps = {
  callsList: ICallItem[];
  statisticActiveTab: StatisticsActiveTabType;
}

const CallsStatisticComponent = ({ callsList, statisticActiveTab }: CallsStatisticComponentProps) => {
  const { t } = useTranslation('translation');
  const receptionStatisticList = useMemo(() => callsList.filter((item: any) =>
    item.callType === 'CROSS_DEPARTMENT_CALL_RED' || item.callType === 'CROSS_DEPARTMENT_CALL_YELLOW' ||
      item.callType === 'CROSS_DEPARTMENT_CALL_GREEN'), [callsList]);
  const nursesStatisticList = useMemo(() => callsList.filter((item: any) =>
    item.callType === 'SERVICE_CALL'), [callsList]);
  const blueCodeStatisticList = useMemo(() => callsList.filter((item: any) =>
    item.callType === 'BLUE_CODE'), [callsList]);

  const handleZoneNameView = (callType: CallType) => {
    switch (callType) {
      case 'CROSS_DEPARTMENT_CALL_RED': return t('department.statistic.red-zone')
      case 'CROSS_DEPARTMENT_CALL_YELLOW': return t('department.statistic.yellow-zone')
      case 'CROSS_DEPARTMENT_CALL_GREEN': return t('department.statistic.green-zone')
      case 'BLUE_CODE': return t('department.statistic.blue-zone')
      case 'SERVICE_CALL': return t('department.statistic.service-zone')
      default: return ''
    }
  };

  const handleCallListView = (activeTab: StatisticsActiveTabType) => {
    switch (activeTab) {
      case 'nurses-department': return (nursesStatisticList ?
        <div>
          {nursesStatisticList.map((item, index: number) =>
            <DepartmentCallTypeComponent
              key={index.toString()}
              title={handleZoneNameView(item.callType)}
              time={timeUtils.convertSecondsToHMS(item.callsAverageDuration)}
              count={item.callsCount}
              color={item?.callType === 'CROSS_DEPARTMENT_CALL_RED' ? 'red'
                : item?.callType === 'CROSS_DEPARTMENT_CALL_YELLOW' ? 'yellow' : 'green'}
            />
          )}
        </div> : null
      )
      case 'blue-code-department':  return (blueCodeStatisticList ?
        <div>
          {blueCodeStatisticList.map((item, index: number) =>
            <DepartmentCallTypeComponent
              key={index.toString()}
              title={handleZoneNameView(item.callType)}
              time={timeUtils.convertSecondsToHMS(item.callsAverageDuration)}
              count={item.callsCount}
              color={item?.callType === 'CROSS_DEPARTMENT_CALL_RED' ? 'red'
                : item?.callType === 'CROSS_DEPARTMENT_CALL_YELLOW' ? 'yellow' : 'green'}
            />
          )}
        </div> : null
      )
      case 'reception-department': return (receptionStatisticList ?
        <div>
          {receptionStatisticList.map((item, index: number) =>
            <DepartmentCallTypeComponent
              key={index.toString()}
              title={handleZoneNameView(item.callType)}
              time={timeUtils.convertSecondsToHMS(item.callsAverageDuration)}
              count={item.callsCount}
              color={item?.callType === 'CROSS_DEPARTMENT_CALL_RED' ? 'red'
                : item?.callType === 'CROSS_DEPARTMENT_CALL_YELLOW' ? 'yellow' : 'green'}
            />
          )}
        </div> : null
      )
      case 'between-departments': return null;
      default: return null;
    }
  };

  return (
    <div className={styles.callsContentContainer}>
      <div className={styles.titleContainer}>
        <p>{statisticActiveTab === 'reception-department' ? t('system.zone') : t('system.type')}</p>
        <p>{t('system.averageTime')}</p>
        <p>{t('system.amount')}</p>
      </div>
      {handleCallListView(statisticActiveTab)}
    </div>
  )
};

export default CallsStatisticComponent;