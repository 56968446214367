import React, {useMemo, useState} from 'react';
import styles from './index.module.scss';
import { ReactComponent as BetweenDepartmentIcon } from '../../../../../assets/sidebar/hospital-sidebar-between-departments.svg';
import { ReactComponent as RightArrow } from '../../../../../assets/right-arrow.svg';
import DepartmentCallTypeComponent from '../department-call-type';
import Button from '../../../../common/button';
import clsx from 'clsx';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const statisticsList = [
  {
    title: 'Ургентні',
    time: '2 хв 32 с',
    count: 2,
    color: '#F54646'
  },
  {
    title: 'Планові',
    time: '24 хв',
    count: 4,
    color: '#BDC2C6'
  }
];

const statisticsListEN = [
  {
    title: 'Urgent',
    time: '2 min 32 s',
    count: 2,
    color: '#F54646'
  },
  {
    title: 'Planned',
    time: '24 min',
    count: 4,
    color: '#BDC2C6'
  }
];

const BetweenDepartmentComponent = () => {
  const { t } = useTranslation('translation');
  const { id } = useParams();
  const navigate = useNavigate();
  const [isActivated, setIsActivated] = useState(false);
  const locale = sessionStorage.getItem('locale');
  const statisticList = useMemo(() => locale === 'en' ? statisticsListEN : statisticsList,
    [locale]);

  return (
    <div className={styles.container}>
      {!isActivated && (
        <div className={styles.notActivatedContainer}>
          <Button color="blue">{t('system.order')}</Button>
          <Button color="blue">{t('system.viewDemo')}</Button>
        </div>
      )}
      <div
        className={clsx(styles.headContainer, !isActivated && styles.notActivatedHeader)}
        // onClick={() => navigate(`/hospital/${id}/between-departments`)}
      >
        <div>
          <BetweenDepartmentIcon />
          <p>{t('main.between-departments.title')}</p>
        </div>
        {isActivated && <RightArrow />}
      </div>
      <div className={styles.averageDeliveryTime}>
        <p>{t('main.between-departments.totalCalls')}</p>
        <p>24</p>
      </div>
      <div className={styles.delayedCalls}>
        <p>{t('main.between-departments.problemCalls')}</p>
        <p className={clsx(styles.zeroValue)}>0</p>
      </div>
      <div className={styles.callsContainer}>
        <div className={styles.titleContainer}>
          <p>{t('main.between-departments.callType')}</p>
          <p>{t('main.between-departments.averageTime')}</p>
          <p>{t('main.between-departments.amount')}</p>
        </div>
        {statisticList.map((item, index: number) =>
          <DepartmentCallTypeComponent
            key={index.toString()}
            title={item.title}
            time={item.time}
            count={item.count}
            color="silver"
          />
        )}
      </div>
    </div>
  )
};

export default BetweenDepartmentComponent;