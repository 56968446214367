import React, { useState } from 'react';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import { ReactComponent as BottomArrow } from '../../../assets/bottom-arrow.svg';
import clsx from 'clsx';
import timeUtils from '../../../utils/timeUtils';

type BlueCodeCallItemComponentProps = {
  callsList: any[];
  startTime: string;
  endTime: string;
  addedTime?: string;
  closedType?: string;
  departmentsList?: any;
};

const BlueCodeCallItemComponent = (props: BlueCodeCallItemComponentProps) => {
  const { t } = useTranslation('translation');
  const { callsList, startTime, endTime, addedTime, closedType, departmentsList } = props;
  const [isOpen, setIsOpen] = useState(false);

  const handleDepartmentNameView = (departmentId: string) => {
    return departmentsList?.find((department: any) => department.id === departmentId)?.name;
  };

  return (
    <div className={styles.callContainer}>
      <div className={styles.color} />
      {!isOpen && (
        <div className={styles.closedTitle}>
          {callsList.map((call, index) =>
            <p>{index !== 0 ? ' · ' : ''}{handleDepartmentNameView(call.departmentCallType.departmentId)}</p>
          )}
        </div>
      )}
      {isOpen && (
        <div>
          {callsList.map((call) => {
            const averageTime = new Date(((new Date(call?.closedAt).getTime() -
              new Date(call?.createdAt).getTime()) / 1000));
            const addedTime = ((new Date(call?.closedAt).getTime() -
              new Date(call?.createdAt).getTime()) / 1000) - call?.departmentCallType.timeToUrgency;

            return (
              <div className={styles.doctorItem} key={call.id}>
                <p>{handleDepartmentNameView(call.departmentCallType.departmentId)}</p>
                <div>
                  <p>{timeUtils.convertSecondsToHMS(averageTime.getTime())}</p>
                  <p
                    className={clsx(addedTime.toString()?.includes('-') ? styles.greenTime : styles.redTime)}
                  >
                    {addedTime.toString()?.includes('-') ? '-' + timeUtils.convertSecondsToHMS(Math.abs(addedTime)) :
                      '+' + timeUtils.convertSecondsToHMS(addedTime)}
                  </p>
                </div>
              </div>
            )
          })}
        </div>
      )}
      <div className={styles.timeContainer}>
        <div>
          <p className={styles.time}>{startTime} - {endTime}</p>
          <p className={styles.addedTime}>{addedTime}</p>
          {closedType && <p className={styles.closedType}>{closedType}</p>}
        </div>
        <p onClick={() => setIsOpen(!isOpen)}>
          {isOpen ? t('system.roll-up') : t('system.details')}
          <BottomArrow className={clsx(isOpen && styles.rotatedArrow)} />
        </p>
      </div>
    </div>
  )
};

export default BlueCodeCallItemComponent;