import React, { useEffect, useMemo, useState } from 'react';
import styles from './index.module.scss';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import DepartmentCallTypeComponent from '../../../main/components/department-call-type';
import { Listbox, Tab } from '@headlessui/react';
import { ReactComponent as BottomArrow } from '../../../../../assets/bottom-arrow.svg';
import CallItemComponent from '../../../../common/call-item';
import moment from 'moment';
import hospitalService, { CallType, ICallItem } from '../../../../../services/hospitalService';
import timeUtils from '../../../../../utils/timeUtils';
import { useParams } from 'react-router';
import { useAppSelector } from '../../../../../store/store';
import { getCalendarPeriod } from '../../../../../store/selectors/calendarSelector';

const ReceptionDepartmentComponent = () => {
  const { t } = useTranslation('translation');
  const [departmentFilter, setDepartmentFilter] = useState<{id: string; name: string}>({
    id: 'all',
    name: t('departments.all')
  });
  const { id } = useParams();
  const [departmentsList, setDepartmentsList] = useState<any>([]);
  const [selectedCallFilterIndex, setSelectedCallFilterIndex] = useState(0);
  const [calls, setCalls] = useState<any>([]);
  const [callsListData, setCallsListData] = useState<ICallItem[] | []>([]);
  const limit = 10000;
  const calendarPeriod = useAppSelector(getCalendarPeriod);

  const totalCalls = useMemo(() => {
    let count = 0;
    callsListData.map((item) => count += item.callsCount);
    return count;
  }, [callsListData]);
  const problemCalls = useMemo(() => {
    let count = 0;
    callsListData.map((item) => count += item.urgentCallsCount);
    return count;
  }, [callsListData]);

  useEffect(() => {
    if (id) {
      (async () => {
        const departmentList = await hospitalService.getHospitalDepartments(id);

        setDepartmentsList(departmentList);
      })();
    }
  }, []);

  useEffect(() => {
    if (id) {
      (async () => {
        const callsData = await hospitalService.getReceptionCallsCount({
          hospitalId: id,
          startDate: calendarPeriod.startDate,
          endDate: calendarPeriod.endDate,
        });
        setCallsListData(callsData);

        const { data } = await hospitalService.getReceptionDepartmentCalls({
          hospitalId: id,
          page: 0,
          startDate: calendarPeriod.startDate,
          endDate: calendarPeriod.endDate,
          ...(selectedCallFilterIndex === 1 && { callEscalationType: 'URGENT' }),
          ...(departmentFilter.id !== 'all' && { departmentId: departmentFilter.id }),
          limit
        });

        setCalls(data);
      })();
    }
  }, [calendarPeriod, selectedCallFilterIndex, departmentFilter]);

  const handleZoneNameView = (callType: CallType) => {
    switch (callType) {
      case 'CROSS_DEPARTMENT_CALL_RED': return t('main.reception-department.red-zone')
      case 'CROSS_DEPARTMENT_CALL_YELLOW': return t('main.reception-department.yellow-zone')
      case 'CROSS_DEPARTMENT_CALL_GREEN': return t('main.reception-department.green-zone')
      default: return ''
    }
  };

  const handleDepartmentNameView = (departmentId: string) => {
    return departmentsList?.find((department: any) => department.id === departmentId)?.name;
  };

  const groupedCalls = (collection: any, iteratee: any) => {
    return collection.reduce((acc: any, item: any) => {
      const key = typeof iteratee === 'function' ? iteratee(item) : item[iteratee];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(item);
      return acc;
    }, {});
  };

  return (
    <div className={styles.container}>
      <div className={styles.statisticsContainer}>
        <div className={styles.statistics}>
          <p>{t('reception-department.statisticsTitle')}</p>
          <div>
            <p>{t('reception-department.totalNumberCalls')}</p>
            <p className={clsx(totalCalls === 0 && styles.zeroValue)}>{totalCalls}</p>
          </div>
          <div>
            <p>{t('reception-department.problemCalls')}</p>
            <p className={clsx(problemCalls === 0 && styles.zeroValue)}>{problemCalls}</p>
          </div>
        </div>
        <div className={styles.zone}>
          <div className={styles.titleContainer}>
            <p>{t('system.zone')}</p>
            <p>{t('system.averageTime')}</p>
            <p>{t('system.amount')}</p>
          </div>
          {callsListData.map((item, index: number) =>
            <DepartmentCallTypeComponent
              key={index.toString()}
              title={handleZoneNameView(item.callType)}
              time={timeUtils.convertSecondsToHMS(item.callsAverageDuration)}
              count={item.callsCount}
              color={item?.callType === 'CROSS_DEPARTMENT_CALL_RED' ? 'red'
                : item?.callType === 'CROSS_DEPARTMENT_CALL_YELLOW' ? 'yellow' : 'green'}
            />
          )}
        </div>
      </div>
      <div className={styles.callsContainer}>
        <div className={styles.callsHeadContainer}>
          <Listbox value={departmentFilter} onChange={setDepartmentFilter}>
            {({ open }) => (
              <>
                <Listbox.Button className={clsx(styles.optionsButton, open && styles.activeButton)}>
                  {departmentFilter?.name ?? 'Усі відділення'}
                  <BottomArrow />
                </Listbox.Button>
                <Listbox.Options className={styles.optionsList}>
                  <Listbox.Option
                    key="all"
                    value={{id: 'all', name: t('departments.all')}}
                    className={clsx(
                      styles.optionItem,
                      departmentFilter?.id === 'all' && styles.activeOptionItem
                    )}
                  >
                    {t('departments.all')}
                  </Listbox.Option>
                  {departmentsList.map((department: any) => (
                    <Listbox.Option
                      key={department.id}
                      value={department}
                      className={clsx(
                        styles.optionItem,
                        departmentFilter?.id === department?.id && styles.activeOptionItem
                      )}
                    >
                      {department.name}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </>
            )}
          </Listbox>
          <Tab.Group selectedIndex={selectedCallFilterIndex} onChange={setSelectedCallFilterIndex}>
            <Tab.List className={styles.tabContainer}>
              <Tab className={clsx(styles.tabItem, selectedCallFilterIndex === 0 && styles.activeTabItem)}>
                {t('system.all-call')}
              </Tab>
              <Tab className={clsx(styles.tabItem, selectedCallFilterIndex === 1 && styles.activeTabItem)}>
                {t('system.problem-call')}
              </Tab>
            </Tab.List>
          </Tab.Group>
        </div>
        <div className={styles.callsHeaderContainer}>
          <div>
            <p className={styles.dateContainer}>
              {Object?.entries(groupedCalls(calls, (call: any) => moment(call.createdAt).format('DD.MM')))?.length > 0 ? Object.entries(groupedCalls(calls, (call: any) => moment(call.createdAt).format('DD.MM')))[0][0] :  moment(new Date()).format('DD.MM')}
            </p>
            <p>{t('system.red-zone')}</p>
          </div>
          <p>{t('system.yellow-zone')}</p>
          <p>{t('system.green-zone')}</p>
          <p>{t('system.polytrauma-zone')}</p>
        </div>
        <div className={styles.callsContent}>
          {Object.entries(groupedCalls(calls, (call: any) => moment(call.createdAt).format('DD.MM')))
            .map((item: any, index: number) => {
              const needViewDate = Object.entries(groupedCalls(calls, (call: any) =>
                moment(call.createdAt).format('DD.MM')))[0][0] !== item[0];

              return (
                <>
                  {needViewDate && <p className={styles.dateContainer}>{item[0]}</p>}
                  <div key={index.toString()}>
                    <div>
                      {item[1].filter((item: any) => item.departmentCallType.callType === 'CROSS_DEPARTMENT_CALL_RED' )
                        .map((call: any, index: number) => {
                          const addedTime = ((new Date(call.closedAt).getTime() - new Date(call.createdAt).getTime()) / 1000) - call.departmentCallType.timeToUrgency;
                          return (
                            <CallItemComponent
                              key={index.toString()}
                              color="red"
                              title={handleDepartmentNameView(call.departmentCallType.departmentId)}
                              startTime={moment(new Date(call.createdAt), "HH").format('LT')}
                              endTime={moment(new Date(call.closedAt), "HH").format('LT')}
                              addedTime={addedTime > 0 ? timeUtils.convertSecondsToHMS(addedTime) : undefined}
                              closedType={call?.callClosingReason}
                            />
                          )
                        })}
                    </div>
                    <div>
                      {item[1].filter((item: any) => item.departmentCallType.callType === 'CROSS_DEPARTMENT_CALL_YELLOW' )
                        .map((call: any, index: number) => {
                          const addedTime = ((new Date(call.closedAt).getTime() - new Date(call.createdAt).getTime()) / 1000) - call.departmentCallType.timeToUrgency;
                          return (
                            <CallItemComponent
                              key={index.toString()}
                              color="yellow"
                              title={handleDepartmentNameView(call.departmentCallType.departmentId)}
                              startTime={moment(new Date(call.createdAt), "HH").format('LT')}
                              endTime={moment(new Date(call.closedAt), "HH").format('LT')}
                              addedTime={addedTime > 0 ? timeUtils.convertSecondsToHMS(addedTime) : undefined}
                              closedType={call?.callClosingReason}
                            />
                          )
                        })}
                    </div>
                    <div>
                      {item[1].filter((item: any) => item.departmentCallType.callType === 'CROSS_DEPARTMENT_CALL_GREEN' )
                        .map((call: any, index: number) => {
                          const addedTime = ((new Date(call.closedAt).getTime() - new Date(call.createdAt).getTime()) / 1000) - call.departmentCallType.timeToUrgency;
                          return (
                            <CallItemComponent
                              key={index.toString()}
                              color="green"
                              title={handleDepartmentNameView(call.departmentCallType.departmentId)}
                              startTime={moment(new Date(call.createdAt), "HH").format('LT')}
                              endTime={moment(new Date(call.closedAt), "HH").format('LT')}
                              addedTime={addedTime > 0 ? timeUtils.convertSecondsToHMS(addedTime) : undefined}
                              closedType={call?.callClosingReason}
                            />
                          )
                        })}
                    </div>
                    <div>
                      {item[1].filter((item: any) => item.departmentCallType.callType === 'BLUE_CODE' )
                        .map((call: any, index: number) => {
                          const addedTime = ((new Date(call.closedAt).getTime() - new Date(call.createdAt).getTime()) / 1000) - call.departmentCallType.timeToUrgency;
                          return (
                            <CallItemComponent
                              key={index.toString()}
                              color="blue"
                              title={handleDepartmentNameView(call.departmentCallType.departmentId)}
                              startTime={moment(new Date(call.createdAt), "HH").format('LT')}
                              endTime={moment(new Date(call.closedAt), "HH").format('LT')}
                              addedTime={addedTime > 0 ? timeUtils.convertSecondsToHMS(addedTime) : undefined}
                              closedType={call?.callClosingReason}
                            />
                          )
                        })}
                    </div>
                  </div>
                </>
              )
          })}
        </div>
      </div>
    </div>
  )
};

export default ReceptionDepartmentComponent;