import React, { useEffect, useMemo, useState } from 'react';
import styles from './index.module.scss';
import CalendarButtonComponent from '../../common/calendar-button';
import { ReactComponent as BackArrow } from '../../../assets/back-button.svg';
import { useTranslation } from 'react-i18next';
import Button from '../../common/button';
import clsx from 'clsx';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import BlueCodeCallItemComponent from '../../common/blue-code-call-item';
import moment from 'moment';
import hospitalService, { ICallItem } from '../../../services/hospitalService';
import { useAppSelector } from '../../../store/store';
import { getCalendarPeriod } from '../../../store/selectors/calendarSelector';
import CallItemComponent from '../../common/call-item';
import timeUtils from '../../../utils/timeUtils';

const groupedCalls = (collection: any, iteratee: any) => {
  return collection.reduce((acc: any, item: any) => {
    const key = typeof iteratee === 'function' ? iteratee(item) : item[iteratee];
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(item);
    return acc;
  }, {});
};

const ProblemCallsPage = () => {
  const { t } = useTranslation('translation');
  const { id } = useParams();
  const navigate = useNavigate();
  const locale = sessionStorage.getItem('locale');
  const [calls, setCalls] = useState<ICallItem[] | []>([]);
  const [departmentsList, setDepartmentsList] = useState<any>([]);
  const receptionCalls = useMemo(() => calls.filter((item: any) =>
    item.departmentCallType.callType === 'CROSS_DEPARTMENT_CALL_RED' ||
    item.departmentCallType.callType === 'CROSS_DEPARTMENT_CALL_YELLOW' ||
    item.departmentCallType.callType === 'CROSS_DEPARTMENT_CALL_GREEN'), [calls]);
  const nursesCalls = useMemo(() => calls.filter((item: any) =>
    item.departmentCallType.callType === 'SERVICE_CALL'), [calls]);
  const blueCodeCalls = useMemo(() => calls.filter((item: any) =>
    item.departmentCallType.callType === 'BLUE_CODE'), [calls]);
  const calendarPeriod = useAppSelector(getCalendarPeriod);

  useEffect(() => {
    if (id) {
      (async () => {
        const { data } = await hospitalService.getReceptionDepartmentCalls({
          hospitalId: id,
          startDate: calendarPeriod.startDate,
          endDate: calendarPeriod.endDate,
          callEscalationType: 'URGENT',
          limit: 10000,
          page: 0
        });
        setCalls(data);
      })();
    }
  }, [calendarPeriod]);

  useEffect(() => {
    if (id) {
      (async () => {
        setDepartmentsList(await hospitalService.getHospitalDepartments(id));
      })();
    }
  }, []);

  const handleDepartmentNameView = (departmentId: string) => {
    return departmentsList?.find((department: any) => department.id === departmentId)?.name;
  };

  const buttonList = [
    {
      id: 'reception-department',
      label: t('problem-calls.reception-department-title')
    },
    // {
    //   id: 'between-departments',
    //   label: t('between-departments-title')
    // },
    {
      id: 'nurses-department',
      label: t('problem-calls.nurses-department-title')
    },
    {
      id: 'blue-code-department',
      label: t('problem-calls.blue-code-department-title')
    },
    {
      id: 'process-department',
      label: t('problem-calls.process-department-title')
    }
  ];
  const [activeTab, setActiveTab] = useState(buttonList[0].id);

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <div>
          <BackArrow onClick={() => navigate(`/hospital/${id}/main`)} />
          <p className={styles.title}>{t('problem-calls.title')}</p>
        </div>
        <CalendarButtonComponent />
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.headButtonContainer}>
          {buttonList.map((item) => {
            return (
              <Button
                key={item.id}
                color="blue"
                className={clsx(activeTab === item.id && styles.activeButton)}
                onClick={() => setActiveTab(item.id)}
                disabled={item.id === 'process-department' ||
                  (item.id === 'reception-department' && !receptionCalls?.length) ||
                  (item.id === 'nurses-department' && !nursesCalls?.length) ||
                  (item.id === 'blue-code-department' && !blueCodeCalls?.length)
                }
              >
                {item.label}
              </Button>
            )
          })}
        </div>
        {activeTab === 'reception-department' && (
          <>
            <div className={styles.receptionCallsContentHeader}>
              <div>
                <p className={styles.dateContainer}>{moment(new Date()).format('DD.MM')}</p>
                <p>{t('system.red-zone')}</p>
              </div>
              <p>{t('system.yellow-zone')}</p>
              <p>{t('system.green-zone')}</p>
            </div>
            <div className={styles.callsContent}>
              {Object.entries(groupedCalls(calls, (call: any) => moment(call.createdAt).format('DD.MM')))
                .map((item: any, index: number) => {
                  const needViewDate = Object.entries(groupedCalls(calls, (call: any) =>
                    moment(call.createdAt).format('DD.MM')))[0][0] !== item[0];

                  return (
                    <>
                      {needViewDate && <p className={styles.dateContainer}>{item[0]}</p>}
                      <div key={index.toString()}>
                        <div>
                          {item[1].filter((item: any) => item.departmentCallType.callType === 'CROSS_DEPARTMENT_CALL_RED' )
                            .map((call: any, index: number) => {
                              const addedTime = ((new Date(call.closedAt).getTime() - new Date(call.createdAt).getTime()) / 1000) - call.departmentCallType.timeToUrgency;
                              return (
                                <CallItemComponent
                                  key={index.toString()}
                                  color="red"
                                  title={handleDepartmentNameView(call.departmentCallType.departmentId)}
                                  startTime={moment(new Date(call.createdAt), "HH").format('LT')}
                                  endTime={moment(new Date(call.closedAt), "HH").format('LT')}
                                  addedTime={addedTime > 0 ? timeUtils.convertSecondsToHMS(addedTime) : undefined}
                                  closedType={call?.callClosingReason}
                                />
                              )
                            })}
                        </div>
                        <div>
                          {item[1].filter((item: any) => item.departmentCallType.callType === 'CROSS_DEPARTMENT_CALL_YELLOW' )
                            .map((call: any, index: number) => {
                              const addedTime = ((new Date(call.closedAt).getTime() - new Date(call.createdAt).getTime()) / 1000) - call.departmentCallType.timeToUrgency;
                              return (
                                <CallItemComponent
                                  key={index.toString()}
                                  color="yellow"
                                  title={handleDepartmentNameView(call.departmentCallType.departmentId)}
                                  startTime={moment(new Date(call.createdAt), "HH").format('LT')}
                                  endTime={moment(new Date(call.closedAt), "HH").format('LT')}
                                  addedTime={addedTime > 0 ? timeUtils.convertSecondsToHMS(addedTime) : undefined}
                                  closedType={call?.callClosingReason}
                                />
                              )
                            })}
                        </div>
                        <div>
                          {item[1].filter((item: any) => item.departmentCallType.callType === 'CROSS_DEPARTMENT_CALL_GREEN' )
                            .map((call: any, index: number) => {
                              const addedTime = ((new Date(call.closedAt).getTime() - new Date(call.createdAt).getTime()) / 1000) - call.departmentCallType.timeToUrgency;
                              return (
                                <CallItemComponent
                                  key={index.toString()}
                                  color="green"
                                  title={handleDepartmentNameView(call.departmentCallType.departmentId)}
                                  startTime={moment(new Date(call.createdAt), "HH").format('LT')}
                                  endTime={moment(new Date(call.closedAt), "HH").format('LT')}
                                  addedTime={addedTime > 0 ? timeUtils.convertSecondsToHMS(addedTime) : undefined}
                                  closedType={call?.callClosingReason}
                                />
                              )
                            })}
                        </div>
                        <div>
                          {item[1].filter((item: any) => item.departmentCallType.callType === 'BLUE_CODE' )
                            .map((call: any, index: number) => {
                              const addedTime = ((new Date(call.closedAt).getTime() - new Date(call.createdAt).getTime()) / 1000) - call.departmentCallType.timeToUrgency;
                              return (
                                <CallItemComponent
                                  key={index.toString()}
                                  color="blue"
                                  title={handleDepartmentNameView(call.departmentCallType.departmentId)}
                                  startTime={moment(new Date(call.createdAt), "HH").format('LT')}
                                  endTime={moment(new Date(call.closedAt), "HH").format('LT')}
                                  addedTime={addedTime > 0 ? timeUtils.convertSecondsToHMS(addedTime) : undefined}
                                  closedType={call?.callClosingReason}
                                />
                              )
                            })}
                        </div>
                      </div>
                    </>
                  )
                })}
            </div>
          </>
        )}
        {activeTab === 'nurses-department' && (
          <>
            <div className={styles.betweenDepartmentsCallsContentHeader}>
              <div>
                <p className={styles.dateContainer}>{moment(new Date()).format('DD.MM')}</p>
                <p>{locale === 'en' ? 'Cardiomonitor' : 'Кардіомонітор'}</p>
              </div>
              <p>{locale === 'en' ? 'Service calls' : 'Сервісні виклики'}</p>
            </div>
            {/*<div className={styles.betweenDepartmentsCallsContent}>*/}
            {/*  {nursesCalls.map((item) => {*/}
            {/*    return (*/}
            {/*      <div>*/}
            {/*        {item.calls.map((call: any) => {*/}
            {/*          return (*/}
            {/*            <CallItemComponent*/}
            {/*              icon={call.icon}*/}
            {/*              color={item.color as 'red' | 'blue' | 'yellow' | 'green'}*/}
            {/*              title={call.title}*/}
            {/*              startTime={call.startTime}*/}
            {/*              endTime={call.endTime}*/}
            {/*              addedTime={call?.addedTime}*/}
            {/*              closedType={call?.closedType}*/}
            {/*            />*/}
            {/*          )*/}
            {/*        })}*/}
            {/*      </div>*/}
            {/*    )*/}
            {/*  })}*/}
            {/*</div>*/}
          </>
        )}
        {activeTab === 'blue-code-department' && (
          <>
            <div className={styles.blueCodeCallsContentHeader}>
              <div>
                <p className={styles.dateContainer}>{moment(new Date()).format('DD.MM')}</p>
                <p>{t('system.polytrauma-zone')}</p>
              </div>
            </div>
            <div className={styles.blueCodeCallsContent}>
              {/*{blueCodeCalls.map((item: any, index: number) => {*/}
              {/*  return (*/}
              {/*    <BlueCodeCallItemComponent*/}
              {/*      key={index.toString()}*/}
              {/*      callsList={item.targets}*/}
              {/*      startTime={item.startTime}*/}
              {/*      endTime={item.endTime}*/}
              {/*      addedTime={item?.addedTime}*/}
              {/*      closedType={item?.closedType}*/}
              {/*    />*/}
              {/*  )*/}
              {/*})}*/}
            </div>
          </>
        )}
      </div>
    </div>
  )
};

export default ProblemCallsPage;