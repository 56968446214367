import React from 'react';
import styles from './index.module.scss';
import Head from '../../components/head';

const StatisticsPage = () => {
  return (
    <div className={styles.wrapperContainer}>
      <img
        src="/images/background.png"
        alt="background"
        className={styles.background}
      />
      <Head activeTab="statistics" />
    </div>
  )
};

export default StatisticsPage;

