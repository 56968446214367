import React, { useEffect, useState } from 'react';
import styles from './index.module.scss'
import { useNavigate } from 'react-router-dom';
import Logo from '../../components/logo';
import hospitalService from '../../services/hospitalService';
import { useTranslation } from 'react-i18next';

type HospitalItemType = {
  id: string;
  name: string;
}

const HospitalList = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('translation', { keyPrefix: 'hospitals-list' });
  const [hospitals, setHospitals] = useState<HospitalItemType[]>([])
  const limit = 20;

  useEffect(() => {
    (async () => {
      const list = await hospitalService.getHospitalList({ page: 0, limit });
      setHospitals(list.data);
    })();
  }, []);

  const sortedHospitals = hospitals.sort((a, b) => {
    return b?.name.localeCompare(a?.name);
  });

  return (
    <div className={styles.container}>
      <div className={styles.listContainer}>
        <Logo />
        <h1 className={styles.title}>
          {t('hospitals')}
        </h1>
        {sortedHospitals?.map((hospital, index) => {
          return (
            <button
              key={index.toString()}
              className={styles.list}
              onClick={() => navigate(`/hospital/${hospital.id}/main`)}
            >
              <div className={styles.name}>{hospital.name}</div>
              <div style={{paddingLeft:'10px'}}>{'>'}</div>
            </button>
          )
        })}
      </div>
    </div>
  );
};

export default HospitalList;