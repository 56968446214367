import apiServices from './apiServices';
import qs from 'qs';

type GetCallsConfig = {
  hospitalId: string;
  departmentId?: string;
  startDate?: string;
  endDate?: string;
  limit?: number;
  page?: number;
  callEscalationType?: 'URGENT'
}

export type CallType = 'CROSS_DEPARTMENT_CALL_RED' |
  'CROSS_DEPARTMENT_CALL_YELLOW' |
  'CROSS_DEPARTMENT_CALL_GREEN' |
  'SERVICE_CALL' |
  'BLUE_CODE';

export type ICallItem = {
  callType: CallType
  callsAverageDuration: number
  callsCount: number
  urgentCallsCount: number
}

const getAllCallsCount = async (props: GetCallsConfig) => {
  const response = await apiServices.get(`calls/count?${qs.stringify({
    callTypes: 'CROSS_DEPARTMENT_CALL_RED,CROSS_DEPARTMENT_CALL_YELLOW,CROSS_DEPARTMENT_CALL_GREEN,SERVICE_CALL,BLUE_CODE',
    ...props
  })}`);

  return response.data.data;
};

const getReceptionCallsCount = async (props: GetCallsConfig) => {
  const response = await apiServices.get(`calls/count?${qs.stringify({
    callTypes: 'CROSS_DEPARTMENT_CALL_RED,CROSS_DEPARTMENT_CALL_YELLOW,CROSS_DEPARTMENT_CALL_GREEN',
    ...props
  })}`);

  return response.data.data;
};

const getBlueCodeCallsCount = async (props: GetCallsConfig) => {
  const response = await apiServices.get(`calls/count?${qs.stringify({
    callTypes: 'BLUE_CODE',
    ...props
  })}`);

  return response.data.data;
};

const getBlueCodeCalls = async (props: GetCallsConfig) => {
  const response = await apiServices.get(`calls?${qs.stringify({
    callTypes: 'BLUE_CODE',
    ...props
  })}`);

  return response.data;
};

const getServiceCallsCount = async (props: GetCallsConfig) => {
  const response = await apiServices.get(`calls/count?${qs.stringify({
    callTypes: 'SERVICE_CALL',
    ...props
  })}`);

  return response.data.data;
};

const getServiceCalls = async (props: GetCallsConfig) => {
  const response = await apiServices.get(`calls?${qs.stringify({
    callTypes: 'SERVICE_CALL',
    ...props
  })}`);

  return response.data;
};

const getReceptionDepartmentCalls = async (props: GetCallsConfig) => {
  const response = await apiServices.get(`calls?${qs.stringify({
    callTypes: 'CROSS_DEPARTMENT_CALL_RED,CROSS_DEPARTMENT_CALL_YELLOW,CROSS_DEPARTMENT_CALL_GREEN,BLUE_CODE',
    ...props
  })}`);

  return response.data;
};

const getHospitalDepartments = async (hospitalId: string) => {
  const response = await apiServices.get(`departments?${qs.stringify({ hospitalId })}`);

  return response.data.data;
};

const getHospitalDepartmentById = async (params: { hospitalId: string; limit: number; page: number; departmentId: string }) => {
  const response = await apiServices.get(`calls?${qs.stringify(params)}`);

  return response.data;
};

const getHospitalList = async (params: { limit: number; page: number }) =>{
  const response = await apiServices.get(`hospitals?${qs.stringify(params)}`);

  return response.data;
};

const getHospitalById = async (hospitalId: string) => {
  const hospital = await apiServices.get(`hospitals/${hospitalId}`);
  
  return hospital.data;
};

export default {
  getAllCallsCount,
  getReceptionCallsCount,
  getBlueCodeCallsCount,
  getServiceCallsCount,
  getHospitalDepartments,
  getHospitalDepartmentById,
  getReceptionDepartmentCalls,
  getServiceCalls,
  getBlueCodeCalls,
  getHospitalList,
  getHospitalById
}