import React, { useState } from 'react';
import styles from './index.module.scss';
import { ReactComponent as CalendarIcon } from '../../../assets/calendar.svg';
import { useTranslation } from 'react-i18next';
import { Listbox } from '@headlessui/react';
import clsx from 'clsx';
import { useAppSelector } from '../../../store/store';
import { getCalendarPeriod } from '../../../store/selectors/calendarSelector';
import { setCalendarPeriod, setEndDateCalendar, setStartDateCalendar } from '../../../store/reducers/calendarReducer';
import { useDispatch } from 'react-redux';
import moment from 'moment/moment';

const CalendarButtonComponent = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('translation', { keyPrefix: 'calendar' });
  const [isOpenManualPeriod, setIsOpenManualPeriod] = useState(false);
  const calendar = useAppSelector(getCalendarPeriod);

  const handleChangeCalendar = (calendarId: 'today' | 'yesterday' | 'past-week' | 'past-month' | 'past-year' | 'all-time') => {
    switch (calendarId) {
      case 'today': {
        dispatch(setStartDateCalendar(moment().startOf('day').utc().valueOf().toString()));
        break;
      }
      case 'yesterday': {
        dispatch(setStartDateCalendar(
          moment().subtract(1, 'days').startOf('day').utc().valueOf().toString()
        ));
        dispatch(setEndDateCalendar(moment().utc().valueOf().toString()));
        break;
      }
      case 'past-week': {
        dispatch(setStartDateCalendar(
          moment().subtract(7, 'days').startOf('day').utc().valueOf().toString()
        ));
        dispatch(setEndDateCalendar(moment().utc().valueOf().toString()));
        break;
      }
      case 'past-month': {
        dispatch(setStartDateCalendar(
          moment().subtract(1, 'months').startOf('month').utc().valueOf().toString()
        ));
        dispatch(setEndDateCalendar(moment().utc().valueOf().toString()));
        break;
      }
      case 'past-year': {
        dispatch(setStartDateCalendar(
          moment().subtract(1, 'years').startOf('year').utc().valueOf().toString()
        ));
        dispatch(setEndDateCalendar(moment().utc().valueOf().toString()));
        break;
      }
      case 'all-time': {
        dispatch(setStartDateCalendar('0'));
        dispatch(setEndDateCalendar(moment().utc().valueOf().toString()));
        break;
      }
      default: {
        dispatch(setStartDateCalendar(
          new Date(new Date().setHours(0, 0, 0, 0)).getTime().toString()
        ));
        dispatch(setEndDateCalendar(moment().utc().valueOf().toString()));
      }
    }
    dispatch(setCalendarPeriod(calendarId));
  };

  const calendarPeriodList = [
    {
      id: 'today',
      label: 'today'
    },
    {
      id: 'yesterday',
      label: 'yesterday'
    },
    {
      id: 'past-week',
      label: 'past-week'
    },
    {
      id: 'past-month',
      label: 'past-month'
    },
    {
      id: 'past-year',
      label: 'past-year'
    },
    {
      id: 'all-time',
      label: 'all-time'
    },
    // {
    //   id: 'select-period',
    //   label: t('select-period')
    // }
  ];

  return (
    <div className={styles.container}>
      <Listbox
        value={calendar.calendarPeriod}
        onChange={(value) => {
          if (value.id !== 'select-period') {
            dispatch(setCalendarPeriod(value.id))
            handleChangeCalendar(value.id);
          } else {
            setIsOpenManualPeriod(true);
          }
        }}
      >
        {({ open }) => (
          <>
            <Listbox.Button className={clsx(styles.todayButton)}>
              <CalendarIcon />
              {t(calendar.calendarPeriod)}
            </Listbox.Button>
            <Listbox.Options className={styles.optionsList}>
              {calendarPeriodList.map((period) => (
                <Listbox.Option
                  key={period.id}
                  value={period}
                  className={clsx(
                    styles.optionItem,
                    calendar.calendarPeriod === period?.id && styles.activeOptionItem
                  )}
                >
                  {t(period.label)}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </>
        )}
      </Listbox>
    </div>
  )
};

export default CalendarButtonComponent;