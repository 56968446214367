import React, {useMemo, useState} from 'react';
import styles from '../nurses-department/index.module.scss';
import clsx from 'clsx';
import DepartmentCallTypeComponent from '../../../main/components/department-call-type';
import { Listbox, Tab } from '@headlessui/react';
import moment from 'moment';
import CallItemComponent from '../../../../common/call-item';
import { useTranslation } from 'react-i18next';
import { ReactComponent as BottomArrow } from '../../../../../assets/bottom-arrow.svg';

const statisticsList = [
  {
    title: 'Ургентні',
    time: '2 хв 32с',
    count: 2,
    color: '#F54646'
  },
  {
    title: 'Планові',
    time: '24 хв',
    count: 4,
    color: '#BDC2C6'
  }
];

const statisticsListEN = [
  {
    title: 'Urgent',
    time: '2 min 32 s',
    count: 2,
    color: '#F54646'
  },
  {
    title: 'Planned',
    time: '24 min',
    count: 4,
    color: '#BDC2C6'
  }
];

const callsList = [
  {
    color: 'red',
    calls: [
      {
        title: 'Травматолог',
        toTitle: 'Урологія',
        startTime: '13:24',
        endTime: '14:44'
      },
      {
        title: 'Уролог',
        toTitle: 'Гастроентерологія',
        startTime: '13:24',
        endTime: '14:44'
      },
      {
        title: 'Травматолог',
        toTitle: 'Нейрохірургія',
        startTime: '13:24',
        endTime: '14:44'
      }
    ]
  },
  {
    color: '',
    calls: [
      {
        title: 'Травматолог',
        toTitle: 'Урологія',
        startTime: '13:24',
        endTime: '14:44',
        addedTime: '+3хв',
        closedType: 'Закрито медперсоналом'
      },
      {
        title: 'Уролог',
        toTitle: 'Гастроентерологія',
        startTime: '17:46',
        endTime: '18:54',
        addedTime: '+5хв'
      },
      {
        title: 'Травматолог',
        toTitle: 'Нейрохірургія',
        startTime: '13:24',
        endTime: '14:44',
        addedTime: '+6хв'
      },
      {
        title: 'Травматолог',
        toTitle: 'Нейрохірургія',
        startTime: '13:24',
        endTime: '14:44',
        addedTime: '+6хв'
      }
    ]
  }
];

const callsListEN = [
  {
    color: 'red',
    calls: [
      {
        title: 'Traumatologist',
        toTitle: 'Urology',
        startTime: '13:24',
        endTime: '14:44'
      },
      {
        title: 'Urologist',
        toTitle: 'Gastroenterology',
        startTime: '13:24',
        endTime: '14:44'
      },
      {
        title: 'Traumatologist',
        toTitle: 'Neurosurgery',
        startTime: '13:24',
        endTime: '14:44'
      }
    ]
  },
  {
    color: '',
    calls: [
      {
        title: 'Traumatologist',
        toTitle: 'Urology',
        startTime: '13:24',
        endTime: '14:44',
        addedTime: '+3 min',
        closedType: 'Closed by personnel'
      },
      {
        title: 'Urologist',
        toTitle: 'Gastroenterology',
        startTime: '17:46',
        endTime: '18:54',
        addedTime: '+5 min'
      },
      {
        title: 'Traumatologist',
        toTitle: 'Neurosurgery',
        startTime: '13:24',
        endTime: '14:44',
        addedTime: '+6 min'
      },
      {
        title: 'Traumatologist',
        toTitle: 'Neurosurgery',
        startTime: '13:24',
        endTime: '14:44',
        addedTime: '+6 min'
      }
    ]
  }
];

const BetweenDepartmentsComponent = () => {
  const { t } = useTranslation('translation');
  const [departmentFilter, setDepartmentFilter] = useState<{id: string; label: string}>({
    id: 'all',
    label: t('departments.all')
  });
  const [selectedCallFilterIndex, setSelectedCallFilterIndex] = useState(0);
  const locale = sessionStorage.getItem('locale');
  const callList = useMemo(() => locale === 'en' ? callsListEN : callsList, [locale]);
  const statisticList = useMemo(() => locale === 'en' ? statisticsListEN : statisticsList, [locale]);

  const departmentList = [
    {
      id: 'all',
      label: t('departments.all')
    },
    {
      id: 'gastroenterology',
      label: t('departments.gastroenterology')
    },
    {
      id: 'neurosurgery',
      label: t('departments.neurosurgery')
    },
    {
      id: 'cardiology',
      label: t('departments.cardiology')
    },
    {
      id: 'urology',
      label: t('departments.urology')
    },
    {
      id: 'defibrillator',
      label: t('departments.defibrillator')
    },
    {
      id: 'toxicology',
      label: t('departments.toxicology')
    },
    {
      id: 'traumatology',
      label: t('departments.traumatology')
    },
    {
      id: 'surgery',
      label: t('departments.surgery')
    }
  ];

  return (
    <div className={styles.container}>
      <div className={styles.statisticsContainer}>
        <div className={styles.statistics}>
          <p>{t('between-departments.statisticsTitle')}</p>
          <div>
            <p>{t('between-departments.totalNumberCalls')}</p>
            <p>24</p>
          </div>
          <div>
            <p>{t('between-departments.problemCalls')}</p>
            <p className={clsx(styles.positiveValue)}>4</p>
          </div>
        </div>
        <div className={styles.zone}>
          <div className={styles.titleContainer}>
            <p>{t('system.type')}</p>
            <p>{t('system.averageTime')}</p>
            <p>{t('system.amount')}</p>
          </div>
          {statisticList.map((item, index) =>
            <DepartmentCallTypeComponent
              key={index.toString()}
              title={item.title}
              time={item.time}
              count={item.count}
              color="silver"
            />
          )}
        </div>
      </div>
      <div className={styles.callsContainer}>
        <div className={styles.callsHeadContainer}>
          <Listbox value={departmentFilter} onChange={setDepartmentFilter}>
            {({ open }) => (
              <>
                <Listbox.Button className={clsx(styles.optionsButton, open && styles.activeButton)}>
                  {departmentFilter?.label ?? 'Усі відділення'}
                  <BottomArrow />
                </Listbox.Button>
                <Listbox.Options className={styles.optionsList}>
                  {departmentList.map((department) => (
                    <Listbox.Option
                      key={department.id}
                      value={department}
                      className={clsx(
                        styles.optionItem,
                        departmentFilter?.id === department?.id && styles.activeOptionItem
                      )}
                    >
                      {department.label}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </>
            )}
          </Listbox>
          <Tab.Group selectedIndex={selectedCallFilterIndex} onChange={setSelectedCallFilterIndex}>
            <Tab.List className={styles.tabContainer}>
              <Tab className={clsx(styles.tabItem, selectedCallFilterIndex === 0 && styles.activeTabItem)}>
                {t('system.all-call')}
              </Tab>
              <Tab className={clsx(styles.tabItem, selectedCallFilterIndex === 1 && styles.activeTabItem)}>
                {t('system.problem-call')}
              </Tab>
            </Tab.List>
          </Tab.Group>
        </div>
        <div className={styles.callsHeaderContainer}>
          <div>
            <p className={styles.dateContainer}>{moment(new Date()).format('DD.MM')}</p>
            <p>{locale === 'en' ? 'Urgent' : 'Ургентні'}</p>
          </div>
          <p>{locale === 'en' ? 'Planned' : 'Планові'}</p>
        </div>
        <div className={styles.callsContent}>
          {callList.map((item) => {
            return (
              <div>
                {item.calls.map((call: any) => {
                  return (
                    <CallItemComponent
                      icon={call.icon}
                      color={item.color as 'red' | 'blue' | 'yellow' | 'green'}
                      title={call.title}
                      toTitle={call.toTitle}
                      startTime={call.startTime}
                      endTime={call.endTime}
                      addedTime={call?.addedTime}
                      closedType={call?.closedType}
                    />
                  )
                })}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
};

export default BetweenDepartmentsComponent;