import React, {useEffect, useState} from 'react';
import styles from './index.module.scss';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import NewAuthInput from '../../../components/input/newAuthInput';
import clsx from 'clsx';
import validationUtils from '../../../utils/validationUtils';
import Logo from '../../../components/logo';
import authService from '../../../services/authService';

const NewSignInPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("translation");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);

  const handleSignIn = async () => {
    try {
      setEmailError(!validationUtils.validateEmail(email));
      setPasswordError(password?.length < 6);
      
      if (!validationUtils.validateEmail(email) || password?.length < 6) return;
      
      await authService.signIn({
        email,
        password
      });
      
      const { isAdminUser, roles } = await authService.getMyProfile();
      if (isAdminUser) navigate("/nav-bar");
      if (!isAdminUser && roles[0]?.role !== 5) navigate(`/hospital/${roles[0]?.department?.hospital?.id}/main`);
      if (!isAdminUser && roles[0]?.role === 5)
        navigate(`/hospital/${roles[0]?.department?.hospital?.id}/department/${roles[0]?.department?.id}`);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const checkForEnter = (event: KeyboardEvent): void => {
      if (event.key === "Enter") {
        handleSignIn()
      }
    }
    window.addEventListener("keydown", checkForEnter)
    return () => window.removeEventListener("keydown", checkForEnter);
  }, [email, password]);


  return (
    <div className={styles.container}>
      <div className={styles.formContainer}>
        <Logo />
        <div className={styles.form}>
          <p className={styles.formHeader}>{t("login.enter")}</p>
          <p className={styles.formTitle}>{t("login.profile")}</p>
          <NewAuthInput
            placeholder="Email"
            name="Email"
            autoComplete="email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            error={emailError || passwordError}
          />
          {emailError && (
            <p className={clsx("mb-2.5", styles.errorMessage)}>
              {t("login.errorEmail")}
            </p>
          )}
          <NewAuthInput
            placeholder="Password"
            name="Password"
            type="password"
            autoComplete="password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            error={passwordError}
          />
          <button onClick={handleSignIn} className={styles.resentButtonTitle}>
            {t("button.title")}
          </button>
          {passwordError && (
            <p className={clsx("mt-2.5", styles.errorMessage)}>
              {t("login.errorSignIn")}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default NewSignInPage;
