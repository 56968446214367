import React from 'react';
import {useTranslation} from 'react-i18next';
import styles from "./index.module.scss"

const Logo = () => {
    const {t} = useTranslation('translation');

    return (
        <div className={styles.container}>
            <img
                src="/images/logo.png"
                alt="background"
                className={styles.logo}
            />
            <p className={styles.title}>
                {t('login.title')}
            </p>
        </div>
    );
};

export default Logo;