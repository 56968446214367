import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import SidebarComponent from '../../components/hospital/sidebar';
import DepartmentsListComponent from '../../components/hospital/departments-list';
import MainComponent from '../../components/hospital/main';
import { useParams } from 'react-router';
import { ReactComponent as ActiveHospitalSidebarReceptionDepartment } from '../../assets/sidebar/active-hospital-sidebar-reception-department.svg';
import { ReactComponent as HospitalSidebarReceptionDepartment } from '../../assets/sidebar/hospital-sidebar-reception-department.svg';
import { ReactComponent as ActiveHospitalSidebarBetweenDepartments } from '../../assets/sidebar/active-hospital-sidebar-between-departments.svg';
import { ReactComponent as HospitalSidebarBetweenDepartments } from '../../assets/sidebar/hospital-sidebar-between-departments.svg';
import { ReactComponent as ActiveHospitalSidebarNursesCall } from '../../assets/sidebar/active-hospital-sidebar-nurses-call.svg';
import { ReactComponent as HospitalSidebarNursesCall } from '../../assets/sidebar/hospital-sidebar-nurses-call.svg';
import { ReactComponent as ActiveHospitalSidebarBlueButton } from '../../assets/sidebar/active-hospital-sidebar-blue-button.svg';
import { ReactComponent as HospitalSidebarBlueButton } from '../../assets/sidebar/hospital-sidebar-blue-button.svg';
import { ReactComponent as ActiveHospitalSidebarProcess } from '../../assets/sidebar/active-hospital-sidebar-process.svg';
import { ReactComponent as HospitalSidebarProcess } from '../../assets/sidebar/hospital-sidebar-process.svg';
import CallsWrapper from '../../components/hospital/calls-wrapper';
import DepartmentComponent from '../../components/hospital/department';
import ProblemCallsPage from '../../components/hospital/problem-calls';
import authService from '../../services/authService';
import { useNavigate } from 'react-router-dom';

const HospitalPage = () => {
  const navigate = useNavigate();
  const { page, departmentId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  
  useEffect(() => {
    (async () => {
      const { isAdminUser, roles } = await authService.getMyProfile();
      if (!isAdminUser && roles[0]?.role === 5)
        navigate(`/hospital/${roles[0]?.department?.hospital?.id}/department/${roles[0]?.department?.id}`);
      setIsLoading(false);
    })();
    
    return setIsLoading(true);
  }, []);

  const menuList = [
    {
      id: 'reception-department',
      img: <HospitalSidebarReceptionDepartment />,
      activeImg: <ActiveHospitalSidebarReceptionDepartment />
    },
    {
      id: 'between-departments',
      img: <HospitalSidebarBetweenDepartments />,
      activeImg: <ActiveHospitalSidebarBetweenDepartments />,
      disabled: true
    },
    {
      id: 'nurses-call',
      img: <HospitalSidebarNursesCall />,
      activeImg: <ActiveHospitalSidebarNursesCall />
    },
    {
      id: 'blue-code',
      img: <HospitalSidebarBlueButton />,
      activeImg: <ActiveHospitalSidebarBlueButton />
    },
    {
      id: 'process',
      img: <HospitalSidebarProcess />,
      activeImg: <ActiveHospitalSidebarProcess />,
      disabled: true
    }
  ];

  const handlePageView = (page: string | undefined, departmentId: string | undefined) => {
    if (!!departmentId) return <DepartmentComponent />;
    
    const profile = JSON.parse(localStorage.getItem('profile') as string);
    
    if (!profile?.isAdminUser && profile?.roles[0]?.role === 5) {
      navigate(`/hospital/${profile?.roles[0]?.department?.hospital?.id}/department/${profile?.roles[0]?.department?.id}`);
      return null
    }

    switch (page) {
      case 'main': return <MainComponent />
      case 'departments-list': return <DepartmentsListComponent />
      case 'between-departments':
      case 'nurses-call':
      case 'blue-code':
      case 'process':
      case 'reception-department': return (
        <CallsWrapper
          page={page}
          title={menuList?.find((item) => item.id === page)?.id ?? ''}
        />
      )
      case 'problem-calls': return <ProblemCallsPage />
      default: return <MainComponent />
    }
  };

  return (!isLoading ?
    <div className={styles.container}>
      <SidebarComponent menuList={menuList} />
      {handlePageView(page, departmentId)}
    </div> : null
  )
};

export default HospitalPage;