import { createSelector } from '@reduxjs/toolkit';

const calendarPeriod = (state: any) => state.calendar;

const getCalendarPeriod = createSelector(
  calendarPeriod,
  (calendarPeriod) => calendarPeriod
);

export {
  getCalendarPeriod
};