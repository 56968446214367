import React, {useState} from 'react';
import styles from '../../pages/monitoring/index.module.scss';

const CopyButton: React.FC<{ copy: string }> = ({ copy}) => {
  const [copied, setCopied] = useState(false);

  const handleCopyClick = () => {
    if (copy !== null) {
      navigator.clipboard.writeText(copy)
        .then(() => {
          setCopied(true);
          setTimeout(() => setCopied(false), 2000);
        })
        .catch(err => console.error('Failed to copy:', err));
    }
  };

  return (
    <div>
      <button
        onClick={handleCopyClick}
      >
        <img
          src="/images/copy.svg"
          alt="copy"
          className={styles.pace}
        />
      </button>
    </div>
  );
};

export default CopyButton;