import React, { useState } from 'react';
import styles from './index.module.scss';
import { ReactComponent as ProcessIcon } from '../../../../../assets/process-main-icon.svg';
import { ReactComponent as RightArrow } from '../../../../../assets/right-arrow.svg';
import Button from '../../../../common/button';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

const ProcessComponent = () => {
  const { t } = useTranslation('translation');
  const [isActivated, setIsActivated] = useState(false);

  return (
    <div className={styles.container}>
      {!isActivated && (
        <div className={styles.notActivatedContainer}>
          <Button color="blue">{t('system.order')}</Button>
          <Button color="blue">{t('system.viewDemo')}</Button>
        </div>
      )}
      <div className={clsx(styles.headContainer, !isActivated && styles.notActivatedHeader)}>
        <div>
          <ProcessIcon />
          <p>{t('main.process.title')}</p>
        </div>
        {isActivated && <RightArrow />}
      </div>
      <div className={styles.scheduledVisits}>
        <p>{t('main.process.planned-visits-title')}</p>
        <div className={styles.countContainer}><p>24 / </p>24</div>
      </div>
      <div className={styles.executionAssignments}>
        <p>{t('main.process.execution-assignments-title')}</p>
        <div className={styles.countContainer}><p>32 / </p>32</div>
      </div>
    </div>
  )
};

export default ProcessComponent;