import React, { ReactNode } from 'react';
import styles from './index.module.scss';
import clsx from 'clsx';

type ButtonProps = {
  children?: string | ReactNode;
  color?: 'red' | 'blue' | 'gray';
  onClick?(): void;
  className?: any;
  disabled?: boolean;
}

const Button = ({ children, onClick, color, className, disabled }: ButtonProps) => {
  return (
    <button
      className={clsx(
        styles.buttonContainer,
        className,
        color === 'red' && styles.redButton,
        color === 'blue' && styles.blueButton,
        color === 'gray' && styles.grayButton,
        !!disabled && styles.disabled
      )}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  )
};

export default Button;