import React, { ReactElement } from 'react';
import styles from './index.module.scss';
import { ReactComponent as RightArrow } from '../../../assets/between-departments/right-gray-arrow.svg';
import { useTranslation } from 'react-i18next';

export type CallItemComponentProps = {
  color?: 'red' | 'yellow' | 'green' | 'blue';
  title: string;
  toTitle?: string;
  startTime: string;
  endTime: string;
  addedTime?: string;
  closedType?: string;
  icon?: ReactElement;
}

const CallItemComponent = ({ color, title, startTime, endTime, addedTime, closedType, icon, toTitle }: CallItemComponentProps) => {
  const { t } = useTranslation('translation');

  const handleColorView = (color: 'blue' | 'yellow' | 'red' | 'green' | undefined) => {
    switch (color) {
      case 'blue': return '#526BC1';
      case 'yellow': return '#EFAE05';
      case 'red': return '#F54646';
      case 'green': return '#2ABE80';
      default: return '#BDC2C6';
    }
  };

  const handleClosedTypeView = (closedType: string) => {
    switch (closedType) {
      case 'CLOSED_BY_DEPARTMENT_POST': return t('system.closedByPersonal');
      default: return undefined;
    }
  };

  return (
    <div className={styles.callContainer}>
      <div className={styles.color} style={{ backgroundColor: handleColorView(color) }} />
      <div className={styles.titleContainer}>
        {icon}
        <p className={styles.title}>{title}</p>
        {toTitle && (
          <p className={styles.toTitle}>
            <RightArrow />
            {toTitle}
          </p>
        )}
      </div>
      <div className={styles.timeContainer}>
        <p className={styles.time}>{startTime} - {endTime}</p>
        <p className={styles.addedTime}>{addedTime}</p>
        {closedType && handleClosedTypeView(closedType) &&
          <p className={styles.closedType}>{handleClosedTypeView(closedType)}</p>
        }
      </div>
    </div>
  )
};

export default CallItemComponent;