import apiServices from './apiServices';
import qs from 'qs';

const getMonitoringData = async (params: { page: number; limit: number }) => {
  const response = await apiServices.get(`telemetry/user-apps?${qs.stringify(params)}`);

  return response.data;
};

const getMonitoringDevicesData = async (params: {
    page?: number;
    limit?: number;
    countryId?: string;
    cityId?: string;
    hospitalId?: string[];
    batteryLevel?: boolean;
    needToTrackTelemetry?: boolean;
    lastTelemetryDiff?: boolean;
}) => {
    const response = await apiServices.get(`devices-telemetry?${qs.stringify(params)}`);

  return response.data.data;
};

const getMonitoringUsersData = async (params: {
    page?: number;
    limit?: number;
    countryId?: string;
    cityId?: string;
    hospitalId?: string[];
    userRoleId?: number[];
    batteryLevel?: boolean;
    needToTrackTelemetry?: boolean;
    firebaseDeviceToken?: boolean;
    lastTelemetryDiff?: boolean;
}) => {
    const response = await apiServices.get(`user-telemetry?${qs.stringify(params)}`);

    return response.data.data;
}

const getInitialFiltersList = async () => {
    const response = await apiServices.get(`user-telemetry/filters`);

    return response.data.data;
};

const getCountryList = async () => {
    const response = await apiServices.get(`countries`);

    return response.data.data;
};

const getCityList = async (params: { countryId?: string }) => {
    const response = await apiServices.get(`cities?${qs.stringify(params)}`);

    return response.data.data;
};

const getHospitalList = async (params: { countryId?: string; cityId?: string }) => {
    const response = await apiServices.get(`hospitals?${qs.stringify(params)}`);

    return response.data.data;
};

export default {
    getMonitoringData,
    getMonitoringDevicesData,
    getMonitoringUsersData,
    getInitialFiltersList,
    getCountryList,
    getCityList,
    getHospitalList
}