import { Auth } from "aws-amplify";
import apiServices from "./apiServices";

interface ISendOtpCode {
  user: any;
  otp: string;
  handleSuccessSignIn(): void;
};

const awsAmplifyConfig = {
  region: process.env.REACT_APP_AWS_COGNITO_REGION,
  userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_WEB_CLIENT_ID,
  authenticationFlowType: 'USER_PASSWORD_AUTH'
};

const getMyProfile = async () => {
  try {
    const profile = await apiServices.get('users/me');

    localStorage.setItem('f_n', profile?.data?.firstName);
    localStorage.setItem('l_n', profile?.data?.lastName);
    localStorage.setItem('role', profile?.data?.roles[0]?.role);
    localStorage.setItem('d_id', profile?.data?.roles[0]?.department?.id);
    localStorage.setItem('profile', JSON.stringify(profile?.data));

    return profile.data;
  } catch (err) {
    throw err;
  }
};

const signIn = async ({ email, password }: { email: string, password: string }) => {
  Auth.configure(awsAmplifyConfig);

  try {
    const session = await Auth.signIn({
      username: email,
      password
    });

    return session;
  } catch (err) {
    throw err;
  }
};

const logOut = async () => {
  try {
    const session = await Auth.signOut();

    return session;
  } catch (err) {
    throw err;
  }
};

const sendOtpCode = async ({
  user,
  otp,
  handleSuccessSignIn,
}: ISendOtpCode) => {
  try {
    const data = await Auth.sendCustomChallengeAnswer(user, otp);

    if (data?.signInUserSession?.idToken?.jwtToken)
      localStorage.setItem('a_t', data.signInUserSession.idToken.jwtToken);
    handleSuccessSignIn();
  } catch (e) {
    throw e;
  }
};

export default {
  signIn,
  sendOtpCode,
  getMyProfile,
  logOut
};
