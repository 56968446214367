import React, { useEffect, useState } from 'react';
import styles from './index.module.scss'
import {
  Accordion, AccordionDetails, AccordionSummary,
  Table, TableBody, TableCell,
  TableContainer,
  TableHead, TableRow, Typography
} from '@mui/material';
import moment from 'moment/moment';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import clsx from 'clsx';
import timeUtils from '../../utils/timeUtils';
import { MonitoringDevicesType } from '../../types/monitoringTypes';
import {useTranslation} from 'react-i18next';

type MonitoringDevicesProps = {
  currentDataDevices: MonitoringDevicesType[];
  setCurrentDataDevices(value: MonitoringDevicesType[]): void;
  isProblemDevicesAccount: any;
  sortField?: string;
  sortDirection?: string;
  toggleSort(value: string): void;
};

const columns = [
  { id: 'hospitalAndDepartment', label: 'Лікарня Відділення' },
  { id: 'locationAndSpace', label: 'Локація Спейс' },
  { id: 'deviceTypeNameAndReason', label: 'Тип девайсу Причина' },
  { id: 'iotAndHubId', label: 'iotId hubId' },
  { id: 'lastTelemetryTs', label: 'Телеметрія' },
  { id: 'batteryLevel', label: 'Рівень заряду' },
  { id: 'fwVersionAndRadioRssi', label: 'fwVersion radioRssi' },
  { id: 'deviceSettingsInfo', label: 'Налаштування девайсів Версія' }
];

const MonitoringDevices = (props: MonitoringDevicesProps) => {
  const {
    currentDataDevices,
    setCurrentDataDevices,
    isProblemDevicesAccount,
    sortField,
    sortDirection,
    toggleSort
  } = props;

  const { t } = useTranslation('translation', { keyPrefix: 'main.monitoring' });
  const [isAscendingBattery, setIsAscendingBattery] = useState(false);
  const [isAscendingHospitalName, setIsAscendingHospitalName] = useState(false);
  const [isAscendingTelemetry, setIsAscendingTelemetry] = useState(false);

  useEffect(() => {
    if (sortField) {
      sortData(sortField);
    }
  }, [sortField, sortDirection]);

  const sortData = (field: string) => {
    if (field === 'sortByBatteryLevel') sortByBatteryLevel();
    if (field === 'sortByHospitalName') sortByHospitalName();
    if (field === 'sortByTelemetryTime') sortByTelemetryTime();
  };

  const sortByBatteryLevel = () => {
    setIsAscendingBattery(!isAscendingBattery);
    const sortBatteryLevel = currentDataDevices.sort((a, b) => {
      if (sortDirection === "asc") return a?.batteryLevel - b?.batteryLevel;
      return b?.batteryLevel - a?.batteryLevel;
    });
    setCurrentDataDevices(sortBatteryLevel);
  };

  const sortByHospitalName = () => {
    setIsAscendingHospitalName(!isAscendingHospitalName);
    const sortHospitalName = currentDataDevices?.sort((a, b) => {
      if (sortDirection === "asc") {
        return a?.device?.location?.space?.department?.hospital?.name.localeCompare(
          b?.device?.location?.space?.department?.hospital?.name);
      }
      return b?.device?.location?.space?.department?.hospital?.name.localeCompare(
        a?.device?.location?.space?.department?.hospital?.name);
    });
    setCurrentDataDevices(sortHospitalName);
  };

  const sortByTelemetryTime = () => {
    setIsAscendingTelemetry(!isAscendingTelemetry);
    const sortTelemetryTime = currentDataDevices.sort((a, b) => {
      if (sortDirection === "desc") return moment(a?.lastEvent).valueOf() - moment(b?.lastEvent).valueOf();
      
      return moment(b?.lastEvent).valueOf() - moment(a?.lastEvent).valueOf();
    });
    setCurrentDataDevices(sortTelemetryTime);
  };

  const handleCellViewLabel = (columnType: string) => {
    switch (columnType) {
      case 'hospitalAndDepartment':
        return <div className={styles.headLabel}>
          <div>
            <p className={styles.firstLabel}>{t('table-header.hospital')}</p>
            <p className={styles.secondLabel}>{t('table-header.department')}</p>
          </div>
          <button onClick={() => toggleSort("sortByHospitalName")}>
            {((sortDirection === 'asc' || sortDirection === undefined) && sortField === "sortByHospitalName") ?
              <ArrowDropUpIcon/> :
              <ArrowDropDownIcon/>
            }
          </button>
        </div>
      case 'locationAndSpace':
        return <div className={styles.headLabel}>
          <div>
            <p className={styles.firstLabel}>{t('table-header.location')}</p>
            <p className={styles.secondLabel}>{t('table-header.space')}</p>
          </div>
        </div>
      case 'deviceTypeNameAndReason':
        return <div>
          <p className={styles.firstLabel}>{t('table-header.device-type')}</p>
          <p className={styles.secondLabel}>{t('table-header.reason')}</p>
        </div>
      case 'iotAndHubId':
        return <div className={styles.headLabel}>
          <div>
            <p className={styles.firstLabel}>iotId</p>
            <p className={styles.secondLabel}>hubId</p>
          </div>
        </div>
      case 'lastTelemetryTs':
        return <div className={styles.headLabel}>
          <p className={styles.firstLabel}>{t('table-header.telemetry')}</p>
          <button onClick={() => toggleSort("sortByTelemetryTime")}>
            {((sortDirection === 'asc' || sortDirection === undefined) && sortField === "sortByTelemetryTime") ?
              <ArrowDropUpIcon/> :
              <ArrowDropDownIcon/>
            }
          </button>
        </div>
      case 'batteryLevel':
        return <div className={styles.headLabel}>
          <p className={styles.firstLabel}>{t('table-header.battery-level')}</p>
          <button onClick={() => toggleSort("sortByBatteryLevel")}>
            {((sortDirection === 'asc' || sortDirection === undefined) && sortField === "sortByBatteryLevel") ?
              <ArrowDropUpIcon/> :
              <ArrowDropDownIcon/>
            }
          </button>
        </div>
      case 'fwVersionAndRadioRssi':
        return <div>
          <p className={styles.firstLabel}>fwVersion</p>
          <p className={styles.secondLabel}>RSSI</p>
        </div>
      case 'deviceSettingsInfo':
        return <div>
          <p className={styles.firstLabel}>{t('table-header.device-settings')}</p>
          <p className={styles.secondLabel}>{t('table-header.version')}</p>
        </div>
      default: return null
    }
  };

  const handleCellView = (value: any, columnType: string, hospital: MonitoringDevicesType) => {
    switch (columnType) {
      case 'hospitalAndDepartment':
        return <div>
          <p>{hospital?.device?.location?.space?.department?.hospital?.name}</p>
            <p className={styles.secondParagraph}>{hospital?.device?.location?.space?.department?.name}</p>
        </div>
      case 'locationAndSpace':
        return <div>
          <p>{hospital?.device?.location?.name}</p>
          <p className={styles.secondParagraph}>{hospital?.device?.location?.space?.name}</p>
        </div>
      case 'deviceTypeNameAndReason':
        return <div>
          <p>{hospital?.device?.deviceType?.name}</p>
          <p className={styles.secondParagraph}>{t(`reason.${hospital?.reason?.toLowerCase()}`)}</p>
        </div>
      case 'iotAndHubId':
        return <div>
          <p>{hospital?.device?.iotId}</p>
          <p className={styles.secondParagraph}>{hospital?.device?.hubId}</p>
        </div>
      case 'lastTelemetryTs':
        return <div>
          <p className={clsx(moment().diff(moment(new Date(hospital?.lastEvent)),
                'hours', true) >= 2 && styles.error)}
          >
            {hospital?.lastEvent === null ? "" : timeUtils.getTimeDifference(hospital?.lastEvent)}
          </p>
          <p className={styles.secondParagraph}>
            {hospital?.lastEvent === null ? "" : moment(new Date(hospital?.lastEvent))?.format('HH:mm DD.MM.YY ')}
          </p>
        </div>
      case 'batteryLevel':
        return <div className={styles.batteryLevelContainer}>
          {hospital?.isCharging && hospital?.batteryLevel <= 20 ?
            <img
              src="/images/charging.svg"
              alt="charging"
              style={{marginRight: '10px'}}
            /> :
            null
          }
          <p className={clsx(value <= 20 && styles.error)}
             style={{marginRight: '50%'}}
          >
            {hospital?.batteryLevel === null ? "" : `${hospital?.batteryLevel}%`}
          </p>
        </div>
      case 'fwVersionAndRadioRssi':
        return <div>
          <p>{hospital?.fwVersion}</p>
          <p className={styles.secondParagraph}>{hospital?.radioRssi}</p>
        </div>
      case 'deviceSettingsInfo':
        return <div>
          <Accordion style={{ backgroundColor: 'transparent', boxShadow: 'none', margin: '0' }}>
            <AccordionSummary
              expandIcon={<ArrowDropDownIcon/>}
              aria-controls="panel2-content"
              id="panel2-header"
              style={{ padding: '0', margin: '0' }}
            >
              <Typography style={{ margin: '0', textAlign: 'justify' }}>
                {t('table-header.settings')}:
              </Typography>
            </AccordionSummary>
            <AccordionDetails style={{ padding: '0' }}>
              <div>advertiseIntervalSec:{hospital?.device?.deviceSettings?.settings?.advertiseIntervalSec}</div>
              <div>rssiSignalThreshold:{hospital.device?.deviceSettings?.settings?.rssiSignalThreshold}</div>
              <div>stopTrackingIntervalMin:{hospital?.device?.deviceSettings?.settings?.stopTrackingIntervalMin}</div>
              <div>telemetryIntervalMin:{hospital?.device?.deviceSettings?.settings?.telemetryIntervalMin}</div>
              <div>trackingIntervalSec:{hospital?.device?.deviceSettings?.settings?.trackingIntervalSec}</div>
            </AccordionDetails>
          </Accordion>
          <div className={styles.secondParagraph}>version:{hospital?.device?.deviceSettings?.version}</div>
        </div>
      default: return value
    }
  };

  return (
    <div className={styles.tableContainer}>
      <TableContainer className={styles.tableWrapper} sx={{maxHeight: '70vh'}}>
        <Table
          stickyHeader
          aria-labelledby="tableTitle"
          size="small"
          className={styles.table}
          sx={{borderCollapse: 'collapse'}}
        >
          <TableHead>
            <TableRow style={{ borderLeft: '4px solid #F2F5F8' }}>
              {columns.map((item: any) => (
                <TableCell
                    key={item.id}
                    align="left"
                    style={{minWidth: 50, maxWidth: 120}}
                    className={styles.tableHeadCell}
                >
                    {handleCellViewLabel(item?.id)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {currentDataDevices.map((row: any, index: number) => {
              return (
                <TableRow
                  tabIndex={-1}
                  key={index.toString()}
                  className={clsx(isProblemDevicesAccount(row?.batteryLevel, row?.lastEvent) ?
                    styles.errorRow : null)}
                >
                  {columns.map((column, index) => {
                    return (
                      <TableCell
                        key={index.toString()}
                        align="left"
                        className={styles.firstParagraph}
                      >
                        {handleCellView(row[column?.id], column?.id, row)}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default MonitoringDevices;