import React, { useState } from 'react';
import styles from './index.module.scss';
import { ReactComponent as BlueCodeIcon } from '../../../../../assets/sidebar/hospital-sidebar-blue-button.svg';
import { ReactComponent as RightArrow } from '../../../../../assets/right-arrow.svg';
import DepartmentCallTypeComponent from '../department-call-type';
import Button from '../../../../common/button';
import clsx from 'clsx';
//import hospitalService from '../../../../../services/hospitalService';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';

const BlueCodeDepartmentComponent = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation('translation');
  // const [totalCalls, setTotalCalls] = useState(0);
  // const [callsAverageDuration, setCallsAverageDuration] = useState(0);
  // const [urgentCallsCount, setUrgentCallsCount] = useState(0);
  const [isActivated, setIsActivated] = useState(false);
  const locale = sessionStorage.getItem('locale');

  // useEffect(() => {
  //   (async () => {
  //     const calls = await hospitalService.getBlueCodeCalls({
  //       hospitalId: 'ebf23a6b-81d6-4210-93de-0f650f844116',
  //       startDate: '0',
  //       endDate: new Date().getTime().toString()
  //     });
  //     setCallsAverageDuration(calls[0].callsAverageDuration);
  //     setUrgentCallsCount(calls[0].urgentCallsCount);
  //     setTotalCalls(calls[0].callsCount)
  //   })();
  // }, []);

  return (
    <div className={styles.container}>
      {!isActivated && (
        <div className={styles.notActivatedContainer}>
          <Button color="blue">{t('system.order')}</Button>
          <Button color="blue">{t('system.viewDemo')}</Button>
        </div>
      )}
      <div
        className={clsx(styles.headContainer, !isActivated && styles.notActivatedHeader)}
        // onClick={() => navigate(`/hospital/${id}/blue-code`)}
      >
        <div>
          <BlueCodeIcon />
          <p>{t('main.blue-code-department.title')}</p>
        </div>
        {isActivated && <RightArrow />}
      </div>
      <div className={styles.averageDeliveryTime}>
        <p>{t('main.blue-code-department.problemCalls')}</p>
        <p className={clsx(styles.zeroValue)}>0</p>
      </div>
      <div className={styles.delayedCalls}>
        <p>{t('main.blue-code-department.callsAverageDuration')}</p>
        <p>{locale === 'en' ? '2 min 13 s' : '2 хв 13 с'}</p>
      </div>
      <div className={styles.callsContainer}>
        <div className={styles.titleContainer}>
          <p>{t('main.blue-code-department.callType')}</p>
          <p>{t('main.blue-code-department.averageTime')}</p>
          <p>{t('main.blue-code-department.amount')}</p>
        </div>
        <DepartmentCallTypeComponent
          title={locale === 'en' ? 'Polytrauma' : 'Політравма'}
          time={locale === 'en' ? '4 min' : '4 хв'}
          count="4"
          color="blue"
        />
      </div>
    </div>
  )
};

export default BlueCodeDepartmentComponent;