import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

type InitialState = {
  startDate: string | null;
  endDate: string | null;
  calendarPeriod: 'today' | 'yesterday' | 'past-week' | 'past-month' | 'past-year' | 'all-time';
};

const initialState: InitialState = {
  startDate: new Date(new Date().setHours(0, 0, 0, 0)).getTime().toString(),
  endDate: moment().utc().valueOf().toString(),
  calendarPeriod: 'today'
};

const calendarSlice = createSlice({
  name: 'calendarReducer',
  initialState,
  reducers: {
    setStartDateCalendar: (
      state,
      action: PayloadAction<InitialState['startDate']>
    ) => {
      state.startDate = action.payload;
    },
    setEndDateCalendar: (
      state,
      action: PayloadAction<InitialState['endDate']>
    ) => {
      state.endDate = action.payload;
    },
    setCalendarPeriod: (
      state,
      action: PayloadAction<InitialState['calendarPeriod']>
    ) => {
      state.calendarPeriod = action.payload;
    }
  }
});

export const { setStartDateCalendar, setEndDateCalendar, setCalendarPeriod } = calendarSlice.actions;

export default calendarSlice.reducer;
