import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import { ReactComponent as RightArrow } from '../../../assets/right-arrow.svg';
import hospitalService from '../../../services/hospitalService';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import CalendarButtonComponent from '../../common/calendar-button';
import authService from '../../../services/authService';

const DepartmentsListComponent = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'departments' });
  const navigate = useNavigate();
  const { id } = useParams();
  const [departmentList, setDepartmentList] = useState<{id: string; name: string}[] | []>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (id) {
      (async () => {
        const departments = await hospitalService.getHospitalDepartments(id);
        const { isAdminUser, roles } = await authService.getMyProfile();
        if (!isAdminUser && roles[0]?.role === 5)
          navigate(`/hospital/${roles[0]?.department?.hospital?.id}/department/${roles[0]?.department?.id}`);
        setDepartmentList(departments);
        setIsLoading(false);
      })();
    }
  }, []);

  return (!isLoading ?
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <p>{t('title')}</p>
        <CalendarButtonComponent />
      </div>
      <div className={styles.contentContainer}>
        {departmentList.map((item, index: number) => {
          return (
            <div
              key={index.toString()}
              className={styles.departmentItemContainer}
              onClick={() => navigate(`../hospital/${id}/department/${item.id}`)}
            >
              <p>{item.name}</p>
              <RightArrow />
            </div>
          )
        })}
      </div>
    </div> : null
  )
};

export default DepartmentsListComponent;