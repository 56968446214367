import React, { useEffect, useMemo, useState } from 'react';
import styles from './index.module.scss';
import { ReactComponent as RightArrow } from '../../../../../assets/right-arrow.svg';
import { ReactComponent as ReceptionIcon } from '../../../../../assets/sidebar/hospital-sidebar-reception-department.svg';
import DepartmentCallTypeComponent from '../department-call-type';
import hospitalService, { CallType, ICallItem } from '../../../../../services/hospitalService';
import timeUtils from '../../../../../utils/timeUtils';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import { useAppSelector } from '../../../../../store/store';
import { getCalendarPeriod } from '../../../../../store/selectors/calendarSelector';

const ReceptionDepartmentComponent = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation('translation', { keyPrefix: 'main.reception-department' });
  const [callsList, setCallsList] = useState<ICallItem[] | []>([]);
  const totalCalls = useMemo(() => {
    let count = 0;
    callsList.map((item) => count += item.callsCount);
    return count;
  }, [callsList]);
  const problemCalls = useMemo(() => {
    let count = 0;
    callsList.map((item) => count += item.urgentCallsCount);
    return count;
  }, [callsList]);
  const calendarPeriod = useAppSelector(getCalendarPeriod);

  const handleZoneNameView = (callType: CallType) => {
    switch (callType) {
      case 'CROSS_DEPARTMENT_CALL_RED': return t('red-zone')
      case 'CROSS_DEPARTMENT_CALL_YELLOW': return t('yellow-zone')
      case 'CROSS_DEPARTMENT_CALL_GREEN': return t('green-zone')
      default: return ''
    }
  };

  useEffect(() => {
    if (id) {
      (async () => {
        const calls = await hospitalService.getReceptionCallsCount({
          hospitalId: id,
          startDate: calendarPeriod.startDate,
          endDate: calendarPeriod.endDate,
        });
        setCallsList(calls);
      })();
    }
  }, [calendarPeriod]);

  return (
    <div className={styles.container}>
      <div
        className={styles.headContainer}
        onClick={() => navigate(`/hospital/${id}/reception-department`)}
      >
        <div>
          <ReceptionIcon />
          <p>{t('title')}</p>
        </div>
        <div>
          <RightArrow className="cursor-pointer" />
        </div>
      </div>
      <div className={styles.totalCalls}>
        <p>{t('totalCalls')}</p>
        <p className={clsx(totalCalls === 0 && styles.zeroValue)}>{totalCalls}</p>
      </div>
      <div className={styles.delayedCalls}>
        <p>{t('problemCalls')}</p>
        <p className={clsx(problemCalls === 0 && styles.zeroValue)}>{problemCalls}</p>
      </div>
      <div className={styles.callsContainer}>
        <div className={styles.titleContainer}>
          <p>{t('zone')}</p>
          <p>{t('averageTime')}</p>
          <p>{t('amount')}</p>
        </div>
        {callsList.map((item, index) => {
          return (
            <DepartmentCallTypeComponent
              key={index.toString()}
              title={handleZoneNameView(item.callType)}
              time={timeUtils.convertSecondsToHMS(item.callsAverageDuration)}
              count={item.callsCount}
              color={item?.callType === 'CROSS_DEPARTMENT_CALL_RED' ? 'red'
                : item?.callType === 'CROSS_DEPARTMENT_CALL_YELLOW' ? 'yellow' : 'green'}
            />
          )
        })}
      </div>
    </div>
  )
};

export default ReceptionDepartmentComponent;