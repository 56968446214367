import React, {ReactElement, useEffect, useState} from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router';
import styles from './index.module.scss';
import { ReactComponent as ActiveHospitalSidebarMain } from '../../../assets/sidebar/active-hospital-sidebar-main.svg';
import { ReactComponent as HospitalSidebarMain } from '../../../assets/sidebar/hospital-sidebar-main.svg';
import { ReactComponent as ActiveHospitalSidebarDepartment } from '../../../assets/sidebar/active-hospital-sidebar-department.svg';
import { ReactComponent as HospitalSidebarDepartment } from '../../../assets/sidebar/hospital-sidebar-department.svg';
import { ReactComponent as DotsIcon } from '../../../assets/user/three-dot.svg';
import { ReactComponent as ExitIcon } from '../../../assets/exit.svg';
import { Listbox } from '@headlessui/react';
import hospitalService from '../../../services/hospitalService';

type SidebarComponentProps = {
  menuList: {
    id: string;
    img: ReactElement;
    activeImg: ReactElement;
    disabled?: boolean;
  }[]
};

const SidebarComponent = ({ menuList }: SidebarComponentProps) => {
  const navigate = useNavigate();
  const { id, page = 'main', departmentId } = useParams();
  const { t } = useTranslation('translation');
  const isHeadDepartment = Number(localStorage.getItem('role')) === 5;
  const { isAdminUser } = JSON.parse(localStorage.getItem('profile') as string);
  const [hospitalName, setHospitalName] = useState(null);
  
  useEffect(() => {
    if (id) {
      (async () => {
        const { name } = await hospitalService.getHospitalById(id);
        setHospitalName(name);
      })();
    }
  }, [id]);

  // const changeLocale = () => {
  //   const locale = sessionStorage.getItem('locale');
  //
  //   if (locale === 'en') {
  //     localStorage.setItem('locale', 'uk');
  //     setLocale('uk');
  //     i18n.changeLanguage('uk');
  //   } else {
  //     localStorage.setItem('locale', 'en');
  //     setLocale('en');
  //     i18n.changeLanguage('en');
  //   }
  //
  //   document.location.reload();
  // };

  const mainMenuList = isHeadDepartment ? [
    {
      id: 'departments-list',
      label: t('sidebar.department'),
      img: <HospitalSidebarDepartment />,
      activeImg: <ActiveHospitalSidebarDepartment />,
      disabled: false
    }
  ] : [
    {
      id: 'main',
      label: t('sidebar.main'),
      img: <HospitalSidebarMain />,
      activeImg: <ActiveHospitalSidebarMain />,
      disabled: false
    },
    {
      id: 'departments-list',
      label: t('sidebar.department'),
      img: <HospitalSidebarDepartment />,
      activeImg: <ActiveHospitalSidebarDepartment />,
      disabled: false
    }
  ];

  return (
    <div className={styles.sidebarContainer}>
      <div>
        <img
          src="/images/logo.png"
          alt="background"
          onClick={() => navigate(`/hospital/${id}/main`)}
        />
        <div className={styles.headMenuContainer}>
          {mainMenuList.map((item, index: number) => {
            return (
              <div
                key={index.toString()}
                className={clsx(
                  styles.menuItem,
                  !departmentId && page === item.id && styles.activeMenuItem,
                  departmentId && item.id === 'departments-list' && styles.activeMenuItem,
                  item.disabled && styles.disabledActiveMenuItem
                )}
                onClick={() => {
                  if (!item.disabled) navigate(`/hospital/${id}/${item.id}`)
                }}
              >
                {(!departmentId && page === item.id) || (departmentId && item.id === 'departments-list')
                  ? item.activeImg
                  : item.img
                }
                <p>{item.label}</p>
              </div>
            )
          })}
        </div>
        {!isHeadDepartment && (
          <div className={styles.headMenuContainer}>
            <p className={styles.menuTitle}>{t('system.calls')}</p>
            {menuList.map((item, index: number) => {
              return (
                <div
                  key={index.toString()}
                  className={clsx(
                    styles.menuItem,
                    page === item.id && styles.activeMenuItem,
                    item.disabled && styles.disabledActiveMenuItem
                  )}
                  onClick={() => {
                    if (!item.disabled) navigate(`/hospital/${id}/${item.id}`)
                  }}
                >
                  {page === item.id ? item.activeImg : item.img}
                  <p>{t(`sidebar.${item.id}`)}</p>
                </div>
              )
            })}
          </div>
        )}
      </div>
      {isAdminUser && hospitalName && (
        <div>
          <div className="flex items-center cursor-pointer mb-2 py-2 px-4 rounded-xl bg-[#526BC1] w-max">
            <p className="font-bold text-white">
              {hospitalName}
            </p>
          </div>
          <div className="flex items-center cursor-pointer mb-2">
            <p
              className="text-[18px] ml-4 hover:text-[#526BC1] hover:font-bold"
              onClick={() => navigate('/hospital-list')}
            >
              {t('sidebar.hospitals-list')}
            </p>
          </div>
          <div className="flex items-center cursor-pointer">
            <p
              className="text-[18px] ml-4 hover:text-[#526BC1] hover:font-bold"
              onClick={() => navigate('/monitoring')}
            >
              {t('sidebar.monitoring')}
            </p>
          </div>
        </div>
      )}
      {!isAdminUser && (
        <div className={styles.headDoctorContainer}>
          <Listbox>
            {({ open }) => (
              <>
                <Listbox.Button className={styles.user}>
                  <p>{t('system.headDoctorTitle')}</p>
                  <DotsIcon />
                </Listbox.Button>
                <Listbox.Options className={styles.userButtonDropDown}>
                  <Listbox.Option key="exit" value="exit">
                    <ExitIcon />
                    {t('system.exit')}
                  </Listbox.Option>
                </Listbox.Options>
              </>
            )}
          </Listbox>
          {/*<Listbox>*/}
          {/*  {({ open }) => (*/}
          {/*    <>*/}
          {/*      <Listbox.Button className={styles.language}>*/}
          {/*        <p>{locale === 'en' ? t('system.en') : t('system.uk')}</p>*/}
          {/*      </Listbox.Button>*/}
          {/*      <Listbox.Options className={styles.languageDropDown}>*/}
          {/*        <Listbox.Option key="en" value="en" onClick={changeLocale}>*/}
          {/*          {t('system.en')}*/}
          {/*        </Listbox.Option>*/}
          {/*        <Listbox.Option key="uk" value="uk" onClick={changeLocale}>*/}
          {/*          {t('system.uk')}*/}
          {/*        </Listbox.Option>*/}
          {/*      </Listbox.Options>*/}
          {/*    </>*/}
          {/*  )}*/}
          {/*</Listbox>*/}
        </div>
      )}
    </div>
  )
};

export default SidebarComponent;