import React, {useMemo, useState} from 'react';
import styles from './index.module.scss';
import { ReactComponent as NursesIcon } from '../../../../../assets/sidebar/hospital-sidebar-nurses-call.svg';
import { ReactComponent as RightArrow } from '../../../../../assets/right-arrow.svg';
import { ReactComponent as CardiomonitorIcon } from '../../../../../assets/cardiomonitor-icon.svg';
import { ReactComponent as NursesServiceCallIcon } from '../../../../../assets/nurses-service-call.svg';
import DepartmentCallTypeComponent from '../department-call-type';
import Button from '../../../../common/button';
import clsx from 'clsx';
// import hospitalService from '../../../../../services/hospitalService';
// import timeUtils from '../../../../../utils/timeUtils';
import { useTranslation } from 'react-i18next';
import {useParams} from 'react-router';
import {useNavigate} from 'react-router-dom';

const statisticsList = [
  {
    title: 'Кардіомонітор',
    icon: <CardiomonitorIcon />,
    time: '2 хв 32 с',
    count: 2,
    color: '#F54646'
  },
  {
    title: 'Сервісні',
    icon: <NursesServiceCallIcon />,
    time: '24 хв',
    count: 4,
    color: '#BDC2C6'
  }
];

const statisticsListEN = [
  {
    title: 'Cardiomonitor',
    icon: <CardiomonitorIcon />,
    time: '2 min 32 s',
    count: 2,
    color: '#F54646'
  },
  {
    title: 'Service',
    icon: <NursesServiceCallIcon />,
    time: '24 min',
    count: 4,
    color: '#BDC2C6'
  }
];

const NursesDepartmentComponent = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation('translation');
  const [isActivated, setIsActivated] = useState(false);
  const [totalCalls, setTotalCalls] = useState(0);
  // const [callsAverageDuration, setCallsAverageDuration] = useState(0);
  // const [urgentCallsCount, setUrgentCallsCount] = useState(0);
  const locale = sessionStorage.getItem('locale');
  const statisticList = useMemo(() => locale === 'en' ? statisticsListEN : statisticsList,
    [locale]);

  // useEffect(() => {
  //   (async () => {
  //     const calls = await hospitalService.getServiceCalls({
  //       hospitalId: 'ebf23a6b-81d6-4210-93de-0f650f844116',
  //       startDate: '0',
  //       endDate: new Date().getTime().toString()
  //     });
  //     setCallsAverageDuration(calls[0].callsAverageDuration);
  //     setUrgentCallsCount(calls[0].urgentCallsCount);
  //     setTotalCalls(calls[0].callsCount)
  //   })();
  // }, []);

  return (
    <div className={styles.container}>
      {!isActivated && (
        <div className={styles.notActivatedContainer}>
          <Button color="blue">{t('system.order')}</Button>
          <Button color="blue">{t('system.viewDemo')}</Button>
        </div>
      )}
      <div
        className={clsx(styles.headContainer, !isActivated && styles.notActivatedHeader)}
        // onClick={() => navigate(`/hospital/${id}/nurses-call`)}
      >
        <div>
          <NursesIcon />
          <p>{t('main.nurses-department.title')}</p>
        </div>
        {isActivated && <RightArrow />}
      </div>
      <div className={styles.averageDeliveryTime}>
        <p>{t('main.nurses-department.totalCalls')}</p>
        <p className={clsx(totalCalls === 0 && styles.zeroValue)}>23</p>
      </div>
      <div className={styles.delayedCalls}>
        <p>{t('main.nurses-department.problemCalls')}</p>
        <p className={clsx(styles.zeroValue)}>0</p>
      </div>
      <div className={styles.callsContainer}>
        <div className={styles.titleContainer}>
          <p>{t('main.nurses-department.callType')}</p>
          <p>{t('main.nurses-department.averageTime')}</p>
          <p>{t('main.nurses-department.amount')}</p>
        </div>
        {statisticList.map((call, index: number) => {
          return (
            <DepartmentCallTypeComponent
              key={index.toString()}
              title={call.title}
              time={call.time}
              count={call.count}
              color="silver"
              icon={call.icon}
            />
          )
        })}
      </div>
    </div>
  )
};

export default NursesDepartmentComponent;