const removeAfterHyphen = (str: string): string => {
  if (str) {
    const hyphenIndex = str.indexOf("-");
    if (hyphenIndex !== -1) return str.substring(0, hyphenIndex);

    return str;
  }

  return "";
}

export default {
  removeAfterHyphen
}