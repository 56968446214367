import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import intervalPlural from 'i18next-intervalplural-postprocessor';
import translationUK from '../locales/uk/translation.json';
import translationEN from '../locales/en/translation.json';
import translationPL from '../locales/pl/translation.json';

const resources = {
  uk: {
    translation: translationUK
  },
  en: {
    translation: translationEN
  },
  pl: {
    translation: translationPL
  }
};

const locale = window.navigator.language === 'pl' || window.navigator.language.includes('pl') ? 'pl' :
  window.navigator.language === 'uk' || window.navigator.language.includes('uk') ? 'uk' : 'en';

i18n
  .use(initReactI18next)
  .use(intervalPlural)
  .init({
    resources,
    lng: locale,
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
