import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import clsx from 'clsx';
import DepartmentCallTypeComponent from '../../../main/components/department-call-type';
import { ReactComponent as BottomArrow } from '../../../../../assets/bottom-arrow.svg';
import { Listbox, Tab } from '@headlessui/react';
import moment from 'moment';
import BlueCodeCallItemComponent from '../../../../common/blue-code-call-item';
import hospitalService, { ICallItem } from '../../../../../services/hospitalService';
import timeUtils from '../../../../../utils/timeUtils';
import { useAppSelector } from '../../../../../store/store';
import { getCalendarPeriod } from '../../../../../store/selectors/calendarSelector';
import { useParams } from 'react-router';

const BlueCodeDepartmentComponent = () => {
  const { id } = useParams();
  const { t } = useTranslation('translation');
  const calendarPeriod = useAppSelector(getCalendarPeriod);
  const [departmentFilter, setDepartmentFilter] = useState<{id: string; name: string}>({
    id: 'all',
    name: t('departments.all')
  });
  const [calls, setCalls] = useState<any>([]);
  const [callsListData, setCallsListData] = useState<ICallItem[] | []>([]);
  const [selectedCallFilterIndex, setSelectedCallFilterIndex] = useState(0);
  const [departmentsList, setDepartmentsList] = useState<any>([]);
  const limit = 10000;

  const totalCalls = useMemo(() => {
    let count = 0;
    callsListData.map((item) => count += item.callsCount);
    return count;
  }, [callsListData]);
  const problemCalls = useMemo(() => {
    let count = 0;
    callsListData.map((item) => count += item.urgentCallsCount);
    return count;
  }, [callsListData]);

  useEffect(() => {
    if (id) {
      (async () => {
        const callsData = await hospitalService.getBlueCodeCallsCount({
          hospitalId: id,
          startDate: calendarPeriod.startDate,
          endDate: calendarPeriod.endDate,
        });
        setCallsListData(callsData);

        const { data } = await hospitalService.getBlueCodeCalls({
          hospitalId: id,
          page: 0,
          startDate: calendarPeriod.startDate,
          endDate: calendarPeriod.endDate,
          ...(selectedCallFilterIndex === 1 && { callEscalationType: 'URGENT' }),
          ...(departmentFilter.id !== 'all' && { departmentId: departmentFilter.id }),
          limit
        });

        const callsGroupedByDays = groupedCalls(data, (call: any) => moment(call?.createdAt)?.format('DD.MM'));

        const callsGroupedByGroupId = Object.entries(callsGroupedByDays).map(([day, callsOnDay]) => {
          return {
            day,
            calls: Object.values(groupedCalls(callsOnDay, (call: any) => call?.groupId))
          }
        })

        setCalls(callsGroupedByGroupId);
      })();
    }
  }, [calendarPeriod, selectedCallFilterIndex, departmentFilter]);

  useEffect(() => {
    if (id) {
      (async () => {
        const departmentList = await hospitalService.getHospitalDepartments(id);

        setDepartmentsList(departmentList);
      })();
    }
  }, []);

  const groupedCalls = (collection: any, iteratee: any) => {
    return collection?.reduce((acc: any, item: any) => {
      const key = typeof iteratee === 'function' ? iteratee(item) : item[iteratee];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(item);
      return acc;
    }, {});
  };

  const handleClosedTypeView = (closedType: string) => {
    switch (closedType) {
      case 'CLOSED_BY_DEPARTMENT_POST': return t('system.closedByPersonal');
      default: return undefined;
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.statisticsContainer}>
        <div className={styles.statistics}>
          <p>{t('blue-code.statisticsTitle')}</p>
          <div>
            <p>{t('blue-code.totalNumberCalls')}</p>
            <p>{totalCalls}</p>
          </div>
          <div>
            <p>{t('blue-code.problemCalls')}</p>
            <p className={clsx(problemCalls > 0 && styles.positiveValue)}>{problemCalls}</p>
          </div>
        </div>
        <div className={styles.zone}>
          <div className={styles.titleContainer}>
            <p>{t('system.type')}</p>
            <p>{t('system.averageTime')}</p>
            <p>{t('system.amount')}</p>
          </div>
          {callsListData.map((item, index: number) =>
            <DepartmentCallTypeComponent
              key={index.toString()}
              title={t('main.blue-code-department.polytraumaCalls')}
              time={timeUtils.convertSecondsToHMS(item.callsAverageDuration)}
              count={item.callsCount}
              color="blue"
            />
          )}
        </div>
      </div>
      <div className={styles.callsContainer}>
        <div className={styles.callsHeadContainer}>
          <Listbox value={departmentFilter} onChange={setDepartmentFilter}>
            {({ open }) => (
              <>
                <Listbox.Button className={clsx(styles.optionsButton, open && styles.activeButton)}>
                  {departmentFilter?.name ?? 'Усі відділення'}
                  <BottomArrow />
                </Listbox.Button>
                <Listbox.Options className={styles.optionsList}>
                  {departmentsList.map((department: any) => (
                    <Listbox.Option
                      key={department.id}
                      value={department}
                      className={clsx(
                        styles.optionItem,
                        departmentFilter?.id === department?.id && styles.activeOptionItem
                      )}
                    >
                      {department.name}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </>
            )}
          </Listbox>
          <Tab.Group selectedIndex={selectedCallFilterIndex} onChange={setSelectedCallFilterIndex}>
            <Tab.List className={styles.tabContainer}>
              <Tab className={clsx(styles.tabItem, selectedCallFilterIndex === 0 && styles.activeTabItem)}>
                {t('system.all-call')}
              </Tab>
              <Tab className={clsx(styles.tabItem, selectedCallFilterIndex === 1 && styles.activeTabItem)}>
                {t('system.problem-call')}
              </Tab>
            </Tab.List>
          </Tab.Group>
        </div>
        <div className={styles.callsHeaderContainer}>
          <div>
            <p className={styles.dateContainer}>{moment(new Date()).format('DD.MM')}</p>
            <p>{t('main.blue-code-department.polytraumaCalls')}</p>
          </div>
        </div>
        <div className={styles.callsContent}>
          {calls?.map((item: any, index: number) => {
            const needViewDate = Object?.entries(groupedCalls(calls, (call: any) =>
              moment(call.createdAt)?.format('DD.MM')))[0][0] !== item.day;

            return (
              <>
                <p className={styles.dateContainer}>{item.day}</p>
                <div key={index.toString()}>
                  <div>
                    {item?.calls?.map((callsList: any, index: number) => {
                      const startTime = new Date(Math.min(...callsList.map((call: any) =>
                        new Date(call.createdAt).getTime())));
                      const endTime = new Date(Math.max(...callsList.map((call: any) =>
                        new Date(call.closedAt).getTime())));
                      //const addedTime = ((new Date(callsList[0]?.closedAt).getTime() - new Date(callsList[0]?.createdAt).getTime()) / 1000) - callsList[0]?.departmentCallType.timeToUrgency;

                      return (
                        <BlueCodeCallItemComponent
                          departmentsList={departmentsList}
                          key={index.toString()}
                          callsList={callsList}
                          startTime={moment(startTime, "HH").format('LT')}
                          endTime={moment(endTime, "HH").format('LT')}
                        />
                      )
                    })}
                  </div>
                </div>
              </>
            )
          })}
        </div>
      </div>
    </div>
  )
};

export default BlueCodeDepartmentComponent;