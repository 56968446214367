import React, { useEffect, useMemo, useState } from 'react';
import styles from './index.module.scss';
import { useParams } from 'react-router';
import clsx from 'clsx';
import { ReactComponent as BackArrow } from '../../../assets/back-button.svg';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../../common/button';
import { Tab } from '@headlessui/react';
import CallsStatisticComponent, { StatisticsActiveTabType } from './components/calls-statistic';
// import { ReactComponent as CardiomonitorIcon } from '../../../assets/cardiomonitor-icon.svg';
// import { ReactComponent as NursesServiceCallIcon } from '../../../assets/nurses-service-call.svg';
import CallItemComponent from '../../common/call-item';
import moment from 'moment';
import CalendarButtonComponent from '../../common/calendar-button';
import hospitalService, { ICallItem } from '../../../services/hospitalService';
import timeUtils from '../../../utils/timeUtils';
import { useAppSelector } from '../../../store/store';
import { getCalendarPeriod } from '../../../store/selectors/calendarSelector';

const DepartmentComponent = () => {
  const { t } = useTranslation('translation');
  const { departmentId, id } = useParams();
  const navigate = useNavigate();
  const [department, setDepartment] = useState<any>(null);
  const [departmentsList, setDepartmentsList] = useState<any>(null);
  const [statisticActiveTab, setStatisticActiveTab] = useState<StatisticsActiveTabType>('reception-department');
  const [callsActiveTab, setCallsActiveTab] = useState(0);
  const [callsProcessActiveTab, setCallsProcessActiveTab] = useState('calls');
  const [allStatisticCallsList, setAllStatisticCallsList] = useState<ICallItem[] | []>([]);
  const [calls, setCalls] = useState([]);
  const totalCalls = useMemo(() => {
    let count = 0;
    allStatisticCallsList.map((item) => count += item.callsCount);
    return count;
  }, [allStatisticCallsList]);
  const problemCalls = useMemo(() => {
    let count = 0;
    allStatisticCallsList.map((item) => count += item.urgentCallsCount);
    return count;
  }, [allStatisticCallsList]);
  const calendarPeriod = useAppSelector(getCalendarPeriod);
  const isHeadDepartment = Number(localStorage.getItem('role')) === 5;

  useEffect(() => {
    if (id) {
      (async () => {
        const departmentList = await hospitalService.getHospitalDepartments(id);

        const department = departmentList.find((item: { id: string; name: string }) =>
          item.id === departmentId);
        setDepartment(department);

        const allStatisticCallsList = await hospitalService.getAllCallsCount({
          departmentId,
          hospitalId: id,
          startDate: calendarPeriod.startDate,
          endDate: calendarPeriod.endDate,
        });
        setDepartmentsList(departmentList);
        setAllStatisticCallsList(allStatisticCallsList);
      })();
    }
  }, [calendarPeriod]);

  useEffect(() => {
    if (id) {
      (async () => {
        const { data } = await hospitalService.getReceptionDepartmentCalls({
          hospitalId: id,
          departmentId: departmentId,
          startDate: calendarPeriod.startDate,
          endDate: calendarPeriod.endDate,
          ...(callsActiveTab === 1 && { callEscalationType: 'URGENT' }),
          limit: 10000,
          page: 0
        });
        setCalls(data);
      })();
    }
  }, [calendarPeriod, callsActiveTab]);

  const groupedCalls = (collection: any, iteratee: any) => {
    return collection.reduce((acc: any, item: any) => {
      const key = typeof iteratee === 'function' ? iteratee(item) : item[iteratee];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(item);
      return acc;
    }, {});
  };

  const departmentsStatisticsList = [
    {
      id: 'reception-department',
      title: t('department.departmentsStatistics.reception-title')
    },
    // {
    //   id: 'between-departments',
    //   title: t('department.departmentsStatistics.between-departments-title')
    // },
    {
      id: 'nurses-department',
      title: t('department.departmentsStatistics.nurses-title')
    },
    {
      id: 'blue-code-department',
      title: t('department.departmentsStatistics.blue-code-title')
    }
  ];

  const handleDepartmentNameView = (departmentId: string) => {
    return departmentsList?.find((department: any) => department.id === departmentId)?.name;
  };

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <div>
          {!isHeadDepartment && <BackArrow onClick={() => navigate(`/hospital/${id}/departments-list`)} />}
          <p>{department?.name}</p>
        </div>
        <CalendarButtonComponent />
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.headContentContainer}>
          <div className={clsx(styles.subContainer, styles.statisticsContainer)}>
            <p className={styles.title}>{t('department.statistic.title')}</p>
            <div className={styles.callsCountContainer}>
              <p>{t('department.statistic.totalNumberCalls')}</p>
              <p className={clsx(totalCalls === 0 && styles.zeroCallsNumber)}>{totalCalls}</p>
            </div>
            <div className={styles.callsCountContainer}>
              <p>{t('department.statistic.problemCalls')}</p>
              <p className={clsx(problemCalls === 0 && styles.zeroCallsNumber)}>{problemCalls}</p>
            </div>
            {/*<div className={styles.callsCountContainer}>*/}
            {/*  <p>{t('department.statistic.problemProcess')}</p>*/}
            {/*  <p className={clsx(styles.zeroCallsNumber)}>0</p>*/}
            {/*</div>*/}
          </div>
          <div className={styles.subContainer}>
            <div className={styles.buttonContainer}>
              {departmentsStatisticsList.map((item, index: number) => {
                return (
                  <Button
                    key={index.toString()}
                    color="blue"
                    className={clsx(item.id === statisticActiveTab && styles.activeButton)}
                    onClick={() => setStatisticActiveTab(item.id as StatisticsActiveTabType)}
                  >
                    {item.title}
                  </Button>
                )
              })}
            </div>
            <CallsStatisticComponent callsList={allStatisticCallsList} statisticActiveTab={statisticActiveTab} />
          </div>
        </div>
        <div className={styles.callsContainer}>
          <div className={styles.headCallsContainer}>
            <div className={styles.callTypesContainer}>
              <Button
                color="blue"
                className={clsx(callsProcessActiveTab === 'calls' && styles.activeButton)}
                onClick={() => setCallsProcessActiveTab('calls')}
              >
                {t('system.calls')}
              </Button>
              <Button
                color="blue"
                className={clsx(callsProcessActiveTab === 'process' && styles.activeButton)}
                onClick={() => setCallsProcessActiveTab('process')}
                disabled
              >
                {t('system.process')}
              </Button>
            </div>
            <Tab.Group selectedIndex={callsActiveTab} onChange={setCallsActiveTab}>
              <Tab.List className={styles.tabContainer}>
                <Tab className={clsx(styles.tabItem, callsActiveTab === 0 && styles.activeTabItem)}>
                  {t('system.all-call')}
                </Tab>
                <Tab className={clsx(styles.tabItem, callsActiveTab === 1 && styles.activeTabItem)}>
                  {t('system.problem-call')}
                </Tab>
              </Tab.List>
            </Tab.Group>
          </div>
          <div className={styles.calls}>
            <div className={styles.titleContainer}>
              <div>
                <p className={styles.dateContainer}>{moment(new Date()).format('DD.MM')}</p>
                <p>{t('department.departmentsStatistics.reception-title')}</p>
              </div>
              <p>{t('department.departmentsStatistics.between-departments-title')}</p>
              <p>{t('department.departmentsStatistics.nurses-title')}</p>
              <p>{t('department.departmentsStatistics.blue-code-title')}</p>
            </div>
            <div className={styles.callsContent}>
              {Object.entries(groupedCalls(calls, (call: any) => moment(call.createdAt).format('DD.MM')))
                .map((item: any, index: number) => {
                  const needViewDate = Object.entries(groupedCalls(calls, (call: any) =>
                    moment(call.createdAt).format('DD.MM')))[0][0] !== item[0];

                  return (
                    <>
                      {needViewDate && <p className={styles.dateContainer}>{item[0]}</p>}
                      <div key={index.toString()}>
                        <div>
                          {item[1].filter((item: any) =>
                            (item.departmentCallType.callType === 'CROSS_DEPARTMENT_CALL_RED' ||
                              item.departmentCallType.callType === 'CROSS_DEPARTMENT_CALL_YELLOW' ||
                              item.departmentCallType.callType === 'CROSS_DEPARTMENT_CALL_GREEN')
                            ).map((call: any, index: number) => {
                              const addedTime = ((new Date(call.closedAt).getTime() - new Date(call.createdAt).getTime()) / 1000) - call.departmentCallType.timeToUrgency;
                              return (
                                <CallItemComponent
                                  key={index.toString()}
                                  color={call.departmentCallType.callType === 'CROSS_DEPARTMENT_CALL_RED' ?
                                    'red' : call.departmentCallType.callType === 'CROSS_DEPARTMENT_CALL_YELLOW' ?
                                      'yellow' : 'green'
                                  }
                                  title={handleDepartmentNameView(call.departmentCallType.departmentId)}
                                  startTime={moment(new Date(call.createdAt), "HH").format('LT')}
                                  endTime={moment(new Date(call.closedAt), "HH").format('LT')}
                                  addedTime={addedTime > 0 ? timeUtils.convertSecondsToHMS(addedTime) : undefined}
                                  closedType={call?.callClosingReason}
                                />
                              )
                            })
                          }
                        </div>
                        <div />
                        <div>
                          {item[1].filter((item: any) => item.departmentCallType.callType === 'SERVICE_CALL')
                            .map((call: any, index: number) => {
                              const addedTime = ((new Date(call.closedAt).getTime() - new Date(call.createdAt).getTime()) / 1000) - call.departmentCallType.timeToUrgency;
                              return (
                                <CallItemComponent
                                  key={index.toString()}
                                  color="red"
                                  title={handleDepartmentNameView(call.departmentCallType.departmentId)}
                                  startTime={moment(new Date(call.createdAt), "HH").format('LT')}
                                  endTime={moment(new Date(call.closedAt), "HH").format('LT')}
                                  addedTime={addedTime > 0 ? timeUtils.convertSecondsToHMS(addedTime) : undefined}
                                  closedType={call?.callClosingReason}
                                />
                              )
                            })
                          }
                        </div>
                        <div>
                          {item[1].filter((item: any) => item.departmentCallType.callType === 'BLUE_CODE')
                            .map((call: any, index: number) => {
                              const addedTime = ((new Date(call.closedAt).getTime() - new Date(call.createdAt).getTime()) / 1000) - call.departmentCallType.timeToUrgency;
                              return (
                                <CallItemComponent
                                  key={index.toString()}
                                  color="red"
                                  title={handleDepartmentNameView(call.departmentCallType.departmentId)}
                                  startTime={moment(new Date(call.createdAt), "HH").format('LT')}
                                  endTime={moment(new Date(call.closedAt), "HH").format('LT')}
                                  addedTime={addedTime > 0 ? timeUtils.convertSecondsToHMS(addedTime) : undefined}
                                  closedType={call?.callClosingReason}
                                />
                              )
                            })
                          }
                        </div>
                      </div>
                    </>
                  )
                })
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default DepartmentComponent;