import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Provider as ReduxProvider } from "react-redux";
import { I18nextProvider, useTranslation } from "react-i18next";
import i18nConfig from "./i18n";
import "./assets/styles/scss/global.scss";
import "./assets/styles/scss/styles.scss";
import StatisticsPage from "./pages/statistics";
import HospitalPage from "./pages/hospital";
import store from "./store/store";
import NewSignInPage from "./pages/auth/new-sign-in";
import HospitalList from "./pages/hospital-list";
import NavBarPage from "./pages/nav-bar";
import MonitoringPage from "./pages/monitoring";

function App() {
  const { i18n } = useTranslation();

  useEffect(() => {
    const selectedLocale = sessionStorage.getItem("locale");

    if (!selectedLocale) {
      const locale =
        window.navigator.language === "pl" ||
        window.navigator.language.includes("pl")
          ? "pl"
          : window.navigator.language === "uk" ||
              window.navigator.language.includes("uk")
            ? "uk"
            : "en";
      sessionStorage.setItem("locale", locale);
      i18n.changeLanguage(locale);
    } else {
      i18n.changeLanguage(selectedLocale);
    }
  }, [i18n]);

  return (
    <I18nextProvider i18n={i18nConfig}>
      <ReduxProvider store={store}>
        <div className="container">
          <Router>
            <div className="contentContainer">
              <Routes>
                <Route path="/monitoring" element={<MonitoringPage />} />
                <Route path="/statistics" element={<StatisticsPage />} />
                <Route path="/sign-in" element={<NewSignInPage />} />
                <Route
                  path="/hospital/:id/:page?"
                  element={<HospitalPage />}
                />
                <Route
                  path="/hospital/:id/department/:departmentId?"
                  element={<HospitalPage />}
                />
                <Route path="/nav-bar" element={<NavBarPage />} />
                <Route path="/hospital-list" element={<HospitalList />} />
                <Route path="/admin" element={<HospitalList />} />
                <Route path="*" element={<NewSignInPage />} />
              </Routes>
            </div>
          </Router>
        </div>
      </ReduxProvider>
    </I18nextProvider>
  );
}

export default App;
