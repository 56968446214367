import React from 'react';
import BurgerMenu from '../../assets/burger-menu';
import styles from './index.module.scss';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';

const Head = (props: { activeTab: 'monitoring' | 'statistics' }) => {
  const navigate = useNavigate();
  const { activeTab } = props;

  return (
    <div className="flex items-center justify-between">
      <img
        src="/images/logo.png"
        alt="background"
        className="max-h-[28px]"
      />
      <div className={styles.tabContainer}>
        <span
          className={clsx(activeTab === 'statistics' && styles.activeTab)}
          onClick={() => navigate('../statistics')}
        >
          Статистика
        </span>
        <span
          className={clsx(activeTab === 'monitoring' && styles.activeTab)}
          onClick={() => navigate('../monitoring')}
        >
          Моніторинг
        </span>
      </div>
      <BurgerMenu className="!cursor-pointer" />
    </div>
  )
};

export default Head;