import React from 'react';
import styles from './index.module.scss';
import ReceptionDepartment from './components/reception-department';
import { ReactComponent as BackArrow } from '../../../assets/back-button.svg';
import NursesDepartmentComponent from './components/nurses-department';
import BlueCodeDepartmentComponent from './components/blue-code-department';
import CalendarButtonComponent from '../../common/calendar-button';
import BetweenDepartmentsComponent from './components/between-departments';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

type CallsWrapperProps = {
  page: string;
  title: string;
}

const CallsWrapper = ({ page, title }: CallsWrapperProps) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation('translation', { keyPrefix: 'sidebar' });

  const handlePageView = (page: string) => {
    switch (page) {
      case 'reception-department': return <ReceptionDepartment />
      case 'nurses-call': return <NursesDepartmentComponent />
      case 'blue-code': return <BlueCodeDepartmentComponent />
      case 'between-departments': return <BetweenDepartmentsComponent />
      default: return null
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <div className={styles.titleContainer}>
          <BackArrow onClick={() => navigate(`/hospital/${id}/main`)} />
          <p>{t(title)}</p>
        </div>
        <CalendarButtonComponent />
      </div>
      <div className={styles.contentContainer}>
        {handlePageView(page)}
      </div>
    </div>
  )
};

export default CallsWrapper;