import React, { useEffect, useMemo, useState } from 'react';
import styles from './index.module.scss';
import clsx from 'clsx';
import DepartmentCallTypeComponent from '../../../main/components/department-call-type';
import { ReactComponent as NursesServiceCallIcon } from '../../../../../assets/nurses-service-call.svg';
import { useTranslation } from 'react-i18next';
import { ReactComponent as BottomArrow } from '../../../../../assets/bottom-arrow.svg';
import { Listbox, Tab } from '@headlessui/react';
import moment from 'moment/moment';
import CallItemComponent from '../../../../common/call-item';
import hospitalService, { ICallItem } from '../../../../../services/hospitalService';
import { useParams } from 'react-router';
import { useAppSelector } from '../../../../../store/store';
import { getCalendarPeriod } from '../../../../../store/selectors/calendarSelector';
import timeUtils from '../../../../../utils/timeUtils';

const NursesDepartmentComponent = () => {
  const { id } = useParams();
  const calendarPeriod = useAppSelector(getCalendarPeriod);
  const { t } = useTranslation('translation');
  const [departmentFilter, setDepartmentFilter] = useState<{id: string; name: string}>({
    id: 'all',
    name: t('departments.all')
  });
  const [calls, setCalls] = useState<any>([]);
  const [callsListData, setCallsListData] = useState<ICallItem[] | []>([]);
  const [selectedCallFilterIndex, setSelectedCallFilterIndex] = useState(0);
  const [departmentsList, setDepartmentsList] = useState<any>([]);
  const limit = 10000;

  const totalCalls = useMemo(() => {
    let count = 0;
    callsListData.map((item) => count += item.callsCount);
    return count;
  }, [callsListData]);
  const problemCalls = useMemo(() => {
    let count = 0;
    callsListData.map((item) => count += item.urgentCallsCount);
    return count;
  }, [callsListData]);

  useEffect(() => {
    if (id) {
      (async () => {
        const callsData = await hospitalService.getServiceCallsCount({
          hospitalId: id,
          startDate: calendarPeriod.startDate,
          endDate: calendarPeriod.endDate,
        });
        setCallsListData(callsData);

        const { data } = await hospitalService.getServiceCalls({
          hospitalId: id,
          page: 0,
          startDate: calendarPeriod.startDate,
          endDate: calendarPeriod.endDate,
          ...(selectedCallFilterIndex === 1 && { callEscalationType: 'URGENT' }),
          ...(departmentFilter.id !== 'all' && { departmentId: departmentFilter.id }),
          limit
        });

        setCalls(data);
      })();
    }
  }, [calendarPeriod, selectedCallFilterIndex, departmentFilter]);

  useEffect(() => {
    if (id) {
      (async () => {
        const departmentList = await hospitalService.getHospitalDepartments(id);

        setDepartmentsList(departmentList);
      })();
    }
  }, []);

  const groupedCalls = (collection: any, iteratee: any) => {
    return collection?.reduce((acc: any, item: any) => {
      const key = typeof iteratee === 'function' ? iteratee(item) : item[iteratee];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(item);
      return acc;
    }, {});
  };

  const handleDepartmentNameView = (departmentId: string) => {
    return departmentsList?.find((department: any) => department?.id === departmentId)?.name;
  };

  return (
    <div className={styles.container}>
      <div className={styles.statisticsContainer}>
        <div className={styles.statistics}>
          <p>{t('nurses-department.statisticsTitle')}</p>
          <div>
            <p>{t('nurses-department.totalNumberCalls')}</p>
            <p>{totalCalls}</p>
          </div>
          <div>
            <p>{t('nurses-department.problemCalls')}</p>
            <p className={clsx(problemCalls > 0 && styles.positiveValue)}>{problemCalls}</p>
          </div>
        </div>
        <div className={styles.zone}>
          <div className={styles.titleContainer}>
            <p>{t('system.type')}</p>
            <p>{t('system.averageTime')}</p>
            <p>{t('system.amount')}</p>
          </div>
          {callsListData.map((item, index: number) =>
            <DepartmentCallTypeComponent
              key={index.toString()}
              title={t('main.nurses-department.serviceCalls')}
              time={timeUtils.convertSecondsToHMS(item.callsAverageDuration)}
              count={item.callsCount}
              color="silver"
            />
          )}
        </div>
      </div>
      <div className={styles.callsContainer}>
        <div className={styles.callsHeadContainer}>
          <Listbox value={departmentFilter} onChange={setDepartmentFilter}>
            {({ open }) => (
              <>
                <Listbox.Button className={clsx(styles.optionsButton, open && styles.activeButton)}>
                  {departmentFilter?.name ?? 'Усі відділення'}
                  <BottomArrow />
                </Listbox.Button>
                <Listbox.Options className={styles.optionsList}>
                  {departmentsList.map((department: any) => (
                    <Listbox.Option
                      key={department.id}
                      value={department}
                      className={clsx(
                        styles.optionItem,
                        departmentFilter?.id === department?.id && styles.activeOptionItem
                      )}
                    >
                      {department.name}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </>
            )}
          </Listbox>
          <Tab.Group selectedIndex={selectedCallFilterIndex} onChange={setSelectedCallFilterIndex}>
            <Tab.List className={styles.tabContainer}>
              <Tab className={clsx(styles.tabItem, selectedCallFilterIndex === 0 && styles.activeTabItem)}>
                {t('system.all-call')}
              </Tab>
              <Tab className={clsx(styles.tabItem, selectedCallFilterIndex === 1 && styles.activeTabItem)}>
                {t('system.problem-call')}
              </Tab>
            </Tab.List>
          </Tab.Group>
        </div>
        <div className={styles.callsHeaderContainer}>
          <div>
            <p className={styles.dateContainer}>{moment(new Date()).format('DD.MM')}</p>
            <p>{t('main.nurses-department.serviceCalls')}</p>
          </div>
        </div>
        <div className={styles.callsContent}>
          {Object?.entries(groupedCalls(calls, (call: any) => moment(call?.createdAt)?.format('DD.MM')))
            ?.map((item: any, index: number) => {
              const needViewDate = Object?.entries(groupedCalls(calls, (call: any) =>
                moment(call.createdAt)?.format('DD.MM')))[0][0] !== item[0];

              return (
                <>
                  {needViewDate && <p className={styles.dateContainer}>{item[0]}</p>}
                  <div key={index.toString()}>
                    <div>
                      {item[1]?.filter((item: any) => item.departmentCallType.callType === 'SERVICE_CALL')
                        .map((call: any, index: number) => {
                          const addedTime = ((new Date(call.closedAt).getTime() - new Date(call.createdAt).getTime()) / 1000) - call.departmentCallType.timeToUrgency;
                          return (
                            <CallItemComponent
                              icon={<NursesServiceCallIcon />}
                              key={index.toString()}
                              title={handleDepartmentNameView(call.departmentCallType.departmentId)}
                              startTime={moment(new Date(call.createdAt), "HH").format('LT')}
                              endTime={moment(new Date(call.closedAt), "HH").format('LT')}
                              addedTime={addedTime > 0 ? timeUtils.convertSecondsToHMS(addedTime) : undefined}
                              closedType={call?.callClosingReason}
                            />
                          )
                        })}
                    </div>
                  </div>
                </>
              )
            })}
        </div>
      </div>
    </div>
  )
};

export default NursesDepartmentComponent;