import React, { ChangeEvent, useRef} from 'react';
import styles from './index.module.scss';
import clsx from 'clsx';

type NewAuthInputProps = {
    name: string;
    type?: 'number' | 'text' | 'password';
    maxLength?: number;
    value: string | number;
    onChange(event: ChangeEvent<HTMLInputElement>): void;
    autoComplete?: string;
    inputClassName?: any;
    error?: boolean;
    placeholder?: string;
    pattern?: any;
}

const NewAuthInput = (props: NewAuthInputProps) => {
    const { name, value, onChange, autoComplete, maxLength, type, inputClassName, error,
        placeholder, pattern } = props;
    const inputRef = useRef<HTMLInputElement>(null);

    return (
        <div className={styles.inputContainer}>
            <input
                ref={inputRef}
                value={value}
                type={type}
                name={name}
                pattern={pattern}
                placeholder={placeholder}
                maxLength={maxLength ?? 100}
                onChange={onChange}
                autoComplete={autoComplete}
                className={clsx(error && styles.inputError, inputClassName && inputClassName)}
            />
        </div>
    )
};

export default NewAuthInput;