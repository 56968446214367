import React, { ReactElement } from 'react';
import styles from './index.module.scss';

type DepartmentCallTypeComponentProps = {
  title: string;
  time: string;
  count: string | number;
  color: 'blue' | 'yellow' | 'red' | 'green' | 'silver';
  icon?: ReactElement;
}

const DepartmentCallTypeComponent = ({ title, time, count, color, icon}: DepartmentCallTypeComponentProps) => {
  const handleColorView = (color: 'blue' | 'yellow' | 'red' | 'green' | 'silver') => {
    switch (color) {
      case 'blue': return '#526BC1';
      case 'yellow': return '#EFAE05';
      case 'red': return '#F54646';
      case 'green': return '#2ABE80';
      case 'silver': return '#BDC2C6';
      default: return '#BDC2C6';
    }
  };

  return (
    <div className={styles.container}>
      <div style={{ backgroundColor: handleColorView(color) }} />
      <p>{icon}{title}</p>
      <p>{time}</p>
      <p>{count}</p>
    </div>
  )
};

export default DepartmentCallTypeComponent;