import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Select,
  TextField,
  Divider,
  MenuItem
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import AdminCheckbox from '../admin-checkbox';
import MenuIcon from '@mui/icons-material/Menu';
import monitoringService from '../../../services/monitoringService';
import { FilterParams, LocationSample } from '../../../pages/monitoring';
import { useTranslation } from 'react-i18next';
import clsx from "clsx";

type FilterSidebarProps = {
  isDevicesMonitoring: boolean;
  selectedFilterParams: FilterParams;
  setSelectedFilterParams: (params: FilterParams) => void;
  fetchData: (params: FilterParams) => Promise<void>;
  isOpenMenu: boolean;
  setIsOpenMenu: (isOpenMenu:boolean) => void;
};

const FilterSidebarComponent = (props: FilterSidebarProps) => {
  const {
    isDevicesMonitoring,
    selectedFilterParams,
    setSelectedFilterParams,
    fetchData,
    isOpenMenu,
    setIsOpenMenu
  } = props;
  const { t } = useTranslation('translation', { keyPrefix: 'main.monitoring' });
  const [countries, setCountries] = useState<LocationSample[]>([]);
  const [cities, setCities] = useState<LocationSample[]>([]);
  const [hospitals, setHospitals] = useState<LocationSample[]>([]);
  const [roles, setRoles] = useState<LocationSample[]>([]);
  const [searchedHospitals, setSearchedHospitals] = useState<LocationSample[]>([]);
  const [isAllHospitalsChecked, setAllHospitalsCheck] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const batteryLevels = [t(`filter-bar.charge-levels.less-than-20`)];
  const lastTelemetryTimes = [t(`filter-bar.telemetry-times.more-than-2`)];
  const tokenStatuses = [
    t(`filter-bar.token-statuses.active`),
    t(`filter-bar.token-statuses.inactive`)
  ];

  useEffect(() => {
    (async () => {
      await fetchInitialFilters();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (selectedFilterParams?.countryId) {
        await fetchCities(selectedFilterParams?.countryId);
      } else {
        setCities([]);
      }
    })();
  }, [selectedFilterParams?.countryId]);

  useEffect(() => {
    (async () => {
      await fetchHospitals(selectedFilterParams?.countryId, selectedFilterParams?.cityId);
    })();
  }, [selectedFilterParams?.countryId, selectedFilterParams?.cityId]);

  useEffect(() => {
    setSearchedHospitals(hospitals);
  }, [hospitals]);

  useEffect(() => {
    setAllHospitalsCheck(false);
  }, [selectedFilterParams?.monitoring]);

  const fetchInitialFilters = async () => {
    const initialFiltersList = await monitoringService.getInitialFiltersList();
    let countryList: LocationSample[] = [];
    let hospitalList: LocationSample[] = [];
    let roleList: LocationSample[] = [];
    initialFiltersList.map((item: any) => {
      if (item.hospitalId) {
        hospitalList.push({id: item.hospitalId, name: item.hospitalName});
      } else if (item.countryId) {
        countryList.push({id: item.countryId, name: item.countryName});
      } else if (item.roleId) {
        roleList.push({id: item.roleId, name: t(`roles.${item.roleName.toLowerCase()}`)});
      }
    });

    setCountries(countryList);
    setHospitals(hospitalList);
    setRoles(roleList);
  };

  const handleResetFilters = async () => {
    const newSelectedFilterParams = {
      hospitalId: [],
      userRoleId: [],
      page: selectedFilterParams?.page,
      limit: selectedFilterParams?.limit,
      monitoring: selectedFilterParams?.monitoring
    };
    setSelectedFilterParams(newSelectedFilterParams);
    setAllHospitalsCheck(false);

    await fetchData(newSelectedFilterParams);
  };

  const fetchCities = async (countryId: string) => {
    setCities(await monitoringService.getCityList({ countryId }));
  };

  const fetchHospitals = async (countryId?: string, cityId?: string) => {
    const hospitalList: LocationSample[] = await monitoringService.getHospitalList({
      countryId,
      cityId
    });

    const newSelectedHospitals = hospitalList.map(item =>
      item.id).filter(id => selectedFilterParams?.hospitalId?.includes(id));
    setSelectedFilterParams({...selectedFilterParams, hospitalId: newSelectedHospitals});

    setAllHospitalsCheck(hospitalList.every(item => newSelectedHospitals.some(id => id === item.id)));
    setHospitals(hospitalList);
  };

  const handleCountryChange = (event: any) => {
    const country = countries?.find(item => item?.name === event.target.value);

    if (selectedFilterParams?.cityId) {
      setSelectedFilterParams({
        ...selectedFilterParams,
        cityId: undefined,
        countryId: country?.id
      });
    } else {
      setSelectedFilterParams({...selectedFilterParams, countryId: country?.id});
    }
  };

  const handleCityChange = (event: any) => {
    const city = cities?.find(item => item?.name === event.target.value);
    setSelectedFilterParams({...selectedFilterParams, cityId: city?.id});
  };

  const handleHospitalInput = (event: any) => {
    const filteredHospitals = hospitals?.filter(
      hospital => hospital?.name?.toLocaleLowerCase()?.includes(event.target.value));

    setAllHospitalsCheck(filteredHospitals.every(item => selectedFilterParams?.hospitalId?.some(id => id === item.id)));
    setSearchedHospitals(filteredHospitals);
  }

  const handleHospitalFilter = (hospitalId: string) => {
    let newSelectedHospitalIds = selectedFilterParams?.hospitalId;
    if (newSelectedHospitalIds?.find((item: string) => item === hospitalId)) {
      newSelectedHospitalIds = newSelectedHospitalIds?.filter((id: any) => id !== hospitalId);
    } else {
      newSelectedHospitalIds?.push(hospitalId);
    }

    setAllHospitalsCheck(searchedHospitals?.every(item =>
        newSelectedHospitalIds?.some(id => id === item.id)) &&
      searchedHospitals?.length > 0);
    setSelectedFilterParams({...selectedFilterParams, hospitalId: newSelectedHospitalIds});
  };

  const handleAllHospitalsCheck = () => {
    let newHospitals = selectedFilterParams?.hospitalId;
    if (!isAllHospitalsChecked) {
      searchedHospitals?.map(item =>
        newHospitals?.indexOf(item.id) === -1 ? newHospitals.push(item.id) : true);
      setSelectedFilterParams({
        ...selectedFilterParams,
        hospitalId: newHospitals
      })
    } else {
      searchedHospitals?.map(item =>
        newHospitals?.indexOf(item.id) !== -1 ? newHospitals?.splice(newHospitals?.indexOf(item.id), 1) : true)
      setSelectedFilterParams({
        ...selectedFilterParams,
        hospitalId: newHospitals
      })
    }
    setAllHospitalsCheck(!isAllHospitalsChecked);
  };

  const handleTestAccountChange = () => {
    const checked = selectedFilterParams?.needToTrackTelemetry;
    setSelectedFilterParams({
      ...selectedFilterParams,
      needToTrackTelemetry: !checked
    });
  };

  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    setIsOpenMenu(width > 1260);

    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, [width]);

  return (
    <div>
      {isOpenMenu ?
        <div className={styles.wrapperContainer}>
          <div className={styles.container}>
            <div
              className={styles.menu}
              onClick={() => setIsOpenMenu(!isOpenMenu)}
            >
              <MenuIcon
                id="menu"
                sx={{
                  fill: "#f5f5f5",
                  marginRight: "10px"
                }}
              />
              <p style={{ color: "#f5f5f5" }}>
                {t(`filter-bar.menu`)}
              </p>
            </div>
            <div className={styles.plateContainer}>
              <FormControl>
                <TextField
                  className={styles.selector}
                  placeholder={t(`filter-bar.search`)}
                  InputProps={{
                    startAdornment: (
                      <SearchIcon
                        sx={{
                          fill: "#727A81",
                          marginRight: "8px",
                          fontSize: "1.5rem"
                        }}
                      />
                    ),
                    style: {
                      borderRadius: "8px",
                    },
                  }}
                  onChange={handleHospitalInput}
                />
              </FormControl>
              <FormControl>
                <Select
                  displayEmpty
                  className={styles.selector}
                  value={countries.find(item => item?.id === selectedFilterParams?.countryId)?.name ?? ''}
                  onChange={handleCountryChange}
                  MenuProps={{
                    PaperProps: {
                      variant: "outlined",
                      elevation: 0,
                      style: {
                        maxHeight: 200,
                        borderRadius: "8px",
                      },
                    },
                  }}
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  <MenuItem value="">{t(`filter-bar.country`)}</MenuItem>
                  <Divider variant="middle" />
                  {countries.map((country, index) => (
                    <MenuItem key={index} value={country?.name}>
                      {t(`filter-bar.countries.${country?.name}`)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl>
                <Select
                  displayEmpty
                  className={styles.selector}
                  value={cities.find(item => item.id === selectedFilterParams?.cityId)?.name ?? ''}
                  onChange={handleCityChange}
                  MenuProps={{
                    PaperProps: {
                      variant: "outlined",
                      elevation: 0,
                      style: {
                        maxHeight: 200,
                        borderRadius: "8px",
                      },
                    },
                  }}
                  inputProps={{ 'aria-label': 'Without label' }}
                  disabled={!countries.find(item => item?.id === selectedFilterParams?.countryId)?.name}
                >
                  <MenuItem value="">{t(`filter-bar.city`)}</MenuItem>
                  <Divider variant="middle" />
                  {cities.map((city, index) => (
                    <MenuItem key={index} value={city?.name}>
                      {t(`filter-bar.cities.ua.${city?.name}`)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl className={styles.hospitalsContainer}>
                <FormControlLabel
                  classes={{label: styles.selectTitle}}
                  control={<Checkbox
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fill: "#ffffff00",
                        border: 2,
                        borderColor: isAllHospitalsChecked ? "#ffffff00" : "#BAC4E6",
                        borderRadius: 1.5,
                        background: isAllHospitalsChecked ? "#526BC1B2" : "#ffffff00",
                      },
                    }}
                    checked={isAllHospitalsChecked}
                    onChange={handleAllHospitalsCheck}
                  />}
                  label={t(`filter-bar.all-hospitals`)}
                />
                <Divider variant="fullWidth" />
                {searchedHospitals.map((hospital, index) => (
                  <FormControlLabel
                    key={index}
                    control={<Checkbox
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fill: "#ffffff00",
                          border: 2,
                          borderColor: selectedFilterParams?.hospitalId?.find((value: string) => {
                            return value === hospital?.id;
                          }) ? "#ffffff00" : "#BAC4E6",
                          borderRadius: 1.5,
                          background: selectedFilterParams?.hospitalId?.find((value: string) => {
                            return value === hospital?.id;
                          }) ? "#526BC1B2" : "#ffffff00",
                        },
                      }}
                      checked={!!selectedFilterParams?.hospitalId?.find((value: string) => {
                        return value === hospital?.id;
                      })}
                      onChange={() => handleHospitalFilter(hospital?.id)}
                    />}
                    label={hospital?.name}
                  />
                ))}
              </FormControl>
              <div className={styles.filterButton}>
                <button style={{ color: "#727A81" }} onClick={handleResetFilters}>{t(`filter-bar.reset-filters`)}</button>
              </div>
            </div>
            {isDevicesMonitoring ? null :
              <div className={styles.plateContainer}>
                <AdminCheckbox
                  data={roles}
                  title={t(`filter-bar.role`)}
                  currentCheckbox="roles"
                  setSelectedFilterParams={setSelectedFilterParams}
                  selectedFilterParams={selectedFilterParams}
                >
                  <img
                    src="/images/account_circle.svg"
                    alt="account_circle"
                    className={styles.iconContainer}
                  />
                </AdminCheckbox>
              </div>
            }
            <div className={clsx(styles.plateContainer, selectedFilterParams?.problemDevices || selectedFilterParams?.problemAccounts ? styles.problemPlate : null)}>
              <AdminCheckbox
                data={batteryLevels}
                title={t(`filter-bar.battery`)}
                currentCheckbox="batteryLevel"
                setSelectedFilterParams={setSelectedFilterParams}
                selectedFilterParams={selectedFilterParams}
              >
                <img
                  src="/images/charger.svg"
                  alt="charger"
                  className={styles.iconContainer}
                />
              </AdminCheckbox>
            </div>
            <div className={clsx(styles.plateContainer, selectedFilterParams?.problemDevices || selectedFilterParams?.problemAccounts ? styles.problemPlate : null)}>
              <AdminCheckbox
                data={lastTelemetryTimes}
                title={t(`filter-bar.telemetry`)}
                currentCheckbox="lastTelemetryDiff"
                setSelectedFilterParams={setSelectedFilterParams}
                selectedFilterParams={selectedFilterParams}
              >
                <img
                  src="/images/telemetry.svg"
                  alt="telemetry"
                  className={styles.iconContainer}
                />
              </AdminCheckbox>
            </div>
            {isDevicesMonitoring ? null :
              <div className={clsx(styles.plateContainer, selectedFilterParams?.problemDevices || selectedFilterParams?.problemAccounts ? styles.problemPlate : null)}>
                <AdminCheckbox
                  data={tokenStatuses}
                  title={t(`filter-bar.token`)}
                  currentCheckbox="firebaseDeviceToken"
                  setSelectedFilterParams={setSelectedFilterParams}
                  selectedFilterParams={selectedFilterParams}
                >
                  <img
                    src="/images/frame_source.svg"
                    alt="frame_source"
                    className={styles.iconContainer}
                  />
                </AdminCheckbox>
              </div>
            }
            <div
              className={styles.plateContainer}
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: "center",
                justifyContent: 'space-between'
              }}
            >
              <span className={styles.labelContainer}>
                <img
                  src="/images/settings.svg"
                  alt="settings"
                  className={styles.iconContainer}
                />
                <p className={styles.title}>{t(`filter-bar.test-account`)}</p>
              </span>
              <Checkbox
                sx={{
                  "& .MuiSvgIcon-root": {
                    fill: "#ffffff00",
                    border: 2,
                    borderColor: selectedFilterParams?.needToTrackTelemetry ? "#ffffff00" : "#BDC2C6",
                    borderRadius: 1.5,
                    background: selectedFilterParams?.needToTrackTelemetry ? "#526BC1B2" : "#ffffff00",
                  },
                }}
                checked={selectedFilterParams?.needToTrackTelemetry}
                onChange={handleTestAccountChange}
              />
            </div>
          </div>
        </div> :
        <div className={styles.menuContainer}>
          <img
            src="/images/menuClose.svg"
            alt="menu"
            className={styles.closeMenu}
            style={{cursor:"pointer"}}
            onClick={() => setIsOpenMenu(!isOpenMenu)}
          />
          <img
            src="/images/search.svg"
            alt="search"
            className={styles.closeMenu}
          />
          <img
            src="/images/account_circle.svg"
            alt="account_circle"
            className={styles.closeMenu}
          />
          <img
            src="/images/charger.svg"
            alt="charger"
            className={styles.closeMenu}
          />
          <img
            src="/images/telemetry.svg"
            alt="telemetry"
            className={styles.closeMenu}
          />
          <img
            src="/images/frame_source.svg"
            alt="frame_source"
            className={styles.closeMenu}
          />
          <img
            src="/images/settings.svg"
            alt="settings"
            className={styles.closeMenu}
          />
        </div>
      }
    </div>
  );
};

export default FilterSidebarComponent;