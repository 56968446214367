import {useNavigate} from "react-router-dom";
import styles from "./index.module.scss"

type BarProps = {
    children: any,
    to: string,
}

const Bar = (props: BarProps) => {
    const {children, to} = props
    const navigate = useNavigate();

    const navigateTo = () => navigate(to)
    return(
        <button className={styles.barContainer} onClick={navigateTo}>
            {children}
        </button>
    )
};
export default Bar;